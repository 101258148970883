import { IStoreEmbedded } from "./Store";
import { IHoursSchema } from "./Hours";
import { ITag } from "./Tag";
import { IIntegrationIds } from "./Integrations";
import { SalesTax } from "./sub/SalesTax";
import { ProductIngredientT } from "./Inventory";
import { WeightUnitTypes } from "./Units";
import { Maybe } from "../utils/types";
import { TaxPolicy } from "./TaxPolicy";

// Special products cause custom handling.
// Gift cards in general avoid incurring operator fees, service fees, and tax and do
// not have price or category editing.
export enum SpecialProductType {
    PhysicalGiftCard = "PHYSICAL_GIFT_CARD",
    DigitalGiftCard = "DIGITAL_GIFT_CARD"
}

export enum SpecialProductCategory {
    GiftCard = "GIFT_CARD"
}

export const getSpecialProductCategory = (
    specialProductType?: SpecialProductType | null
): Maybe<SpecialProductCategory> => {
    switch (specialProductType) {
        case SpecialProductType.PhysicalGiftCard:
        case SpecialProductType.DigitalGiftCard:
            return SpecialProductCategory.GiftCard;
        default:
            return null;
    }
};

export type ProductColor = {
    hsl: string;
    comment: string;
};

export interface IProduct {
    _id: string;
    integrationIds?: IIntegrationIds;
    price: number;
    priceByWeight?: { unit: WeightUnitTypes; perUnit: number } | null;
    points?: number;
    pointsAfterPromotion?: number;

    // Info about product
    name: string;
    category: string;
    categoryId?: string;
    image?: string | null;
    colors?: ProductColor[];
    soldOut: boolean;
    /** @deprecated This field is no longer used. Please use `soldOut` and / or `soldOutDates` only. */
    soldOutToday: boolean;
    soldOutDates: { from: Date | null; until: Date | null };
    tags?: ITag[];
    pun?: string;

    description?: string | null;
    hours: IHoursSchema | null;

    isCatering?: boolean;
    numberFeeds?: number;
    minimumQuantity?: number;

    /**
     * Store information for the product.
     */
    store: IStoreEmbedded;
    upsell: boolean;
    isTemplate?: boolean;

    // Addons info
    addonGroups: AddonGroup[];

    /**
     * Modifier group ids for product. Replaces `addonGroups` in new menu architecture.
     */
    modifierGroups?: string[];

    /**
     * The number of times the product has been purchased.
     */
    purchaseCount?: number;

    /**
     * The last date the product was purchased.
     */
    lastPurchaseDate: Date | null;

    // Soft Delete
    isArchived?: Date;

    // Tax
    taxInfo?: SalesTax | null;
    taxPolicies?: TaxPolicy[];

    // Inventory Management
    inventoryItems?: ProductIngredientT[];

    // Fulfillment Methods
    fulfillmentMethods: FulfillmentMethods;

    // Special Product Type
    specialProductType?: SpecialProductType;

    //** Channel Visibility (i.e. show on App, Kiosk, Register, Online Ordering) */
    channelVisibility?: ProductChannelVisibility;

    /**
     * The ID of the store menu that this product was surfaced from, if any.
     * If undefined, this is from the default menu.
     */
    menu?: string;
}

export type FulfillmentMethods = {
    isDelivery: boolean;
    isPickup: boolean;
    isDineIn: boolean;
};

export type ProductChannelVisibility = {
    app: boolean;
    kiosk: boolean;
    register: boolean;
    onlineOrdering: boolean;
};

export type Addon = {
    _id: string;
    integrationIds?: IIntegrationIds;
    soldOut: boolean;

    /** @deprecated This field is no longer used. Please use `soldOut` and / or `soldOutDates` only. */
    soldOutToday: boolean;

    soldOutDates: { from: Date | null; until: Date | null };

    name: string;

    price: number;

    /** @deprecated Never implemented. Intended for a soft delete. */
    isArchived?: Date;

    /** Inventory Management items */
    inventoryItems?: ProductIngredientT[];

    /** Whether this addon should be pre-selected when adding to the cart. */
    preSelected?: boolean;
};

export type AddonGroup = {
    _id: string;
    integrationIds?: IIntegrationIds;
    name: string;
    required: boolean;
    limit?: number | null;
    addons: Addon[];
    supportsMultiple?: boolean;

    // Soft delete
    isArchived?: Date;
};

/** An `IProduct` with `addonGroups` converted to a list of reference IDs. */
export type NormalizedIProduct = Omit<IProduct, "addonGroups"> & {
    addonGroups: string[];
};

/** An `AddonGroup` stripped of any unique identifiers. */
export type AnonymousAddonGroup = Omit<
    AddonGroup,
    "_id" | "addons" | "integrationIds"
> & { addons: Omit<Addon, "_id" | "integrationIds">[] };

/**
 * A mapping of ephemeral reference IDs to addon group information that includes
 * product specific ID values for addon groups and addons.
 *
 * This is used to de-duplicate addon groups across products without losing the ID information
 * that is unique by product.
 */
export type AddonGroupRefs = Record<
    string,
    {
        /** Ephemeral ID used by products to reference de-duped addon groups. */
        id: string;

        /** The addon group itself, with IDs stripped. */
        ag: AnonymousAddonGroup;

        /** A mapping of product IDs to the stripped ID values. */
        pRef: Record<string, { id: string; addons: string[] }>;
    }
>;
