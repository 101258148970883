export type StoreMenuFields = {
    store: string;
    name: string;
    categories: string[];
    priceAdjustment?: PriceAdjustment | null;
    productExclusions: string[];
    productPriceOverrides?: SetPriceOverride[];
    context?: {
        fulfillment?: FulfillmentType[];
        channel?: ChannelType[];
        dateRange?: {
            start: Date;
            end: Date;
        };
        schedule?: Schedule;
    };
};

export type SetPriceOverride = {
    value: number;
    id: string;
};

export type ScheduleBlock = {
    start: {
        hour: number;
        minute: number;
    };
    end: {
        hour: number;
        minute: number;
    };
};

export type Schedule = {
    monday: ScheduleBlock[];
    tuesday: ScheduleBlock[];
    wednesday: ScheduleBlock[];
    thursday: ScheduleBlock[];
    friday: ScheduleBlock[];
    saturday: ScheduleBlock[];
    sunday: ScheduleBlock[];
};

export enum FulfillmentType {
    Pickup = "pickup",
    Delivery = "delivery",
    DineIn = "dine_in"
}

export enum ChannelType {
    App = "app",
    Online = "online",
    Kiosk = "kiosk",
    Catering = "catering",
    Register = "register"
}

export interface PriceAdjustment {
    value: number;
    adjustmentType: PriceAdjustmentType;
    roundTo?: number | null;
    adjustModifiers: boolean;
}

export enum PriceAdjustmentType {
    Percentage = "percentage",
    Flat = "flat"
}

export type StoreMenu = StoreMenuFields & {
    _id: string;
    createdAt: Date;
    updatedAt: Date;
};
