import {
    Fulfillment,
    FulfillmentTypeEnum,
    IHoursSchema,
    IProduct,
    IPromotion,
    PaymentProvider,
    ThirdPartySource,
    TransactionSource,
    SpecialProductCategory
} from "./";
import { IAddress } from "./Address";
import { IAttendanceSettings } from "./Attendance";
import { IChargeFeePolicy } from "./ChargeFeePolicies";
import { IDocumentWithTimestamps, ObjectId } from "./globals";
import { IIntegration } from "./Integrations";
import { PayoutPolicy } from "./payouts/PayoutPolicy";
import { PayoutChannel } from "./payouts/PayoutChannel";
import { IPOSIntegration } from "./POSIntegrations";
import { ITag } from "./Tag";
import { IUser } from "./User";

export type KioskCustomerIdentifier = "ORDER_NUMBER" | "TABLE_NUMBER";
export enum KioskCustomerIdentifierEnum {
    orderNumber = "ORDER_NUMBER",
    tableNumber = "TABLE_NUMBER"
}
export type KioskFulfillment = "PICKUP" | "DINE_IN";
export type KioskTipDisplay = "FLAT" | "PERCENT";
export type Language = "chinese";
export enum KioskMenuDisplayType {
    GALLERY = "GALLERY",
    CLASSIC = "CLASSIC"
}

export type FeePolicyRules = {
    cartMax?: number;
    cartMin?: number;
    fulfillments?: Fulfillment[];
    transactionSources?: TransactionSource[];
    excludedPaymentProviders?: PaymentProvider[];
    isCatering?: boolean;
    isQr?: boolean;
};

export type FeePolicyWithTotal = {
    fee: FeePolicy;
    total: number; // the price, in dollars
};

export enum FeePolicyPayer {
    Customer = "customer",
    Store = "store",
    Snackpass = "snackpass"
}

export enum FeePolicyRecipient {
    Snackpass = "snackpass",
    Store = "store",
    Integration = "integration"
}

// TODO: Consider the need for appending a version (e.g. _V2) if
// the strategy implementation changes over time.
export enum SnackpassConvenienceFeeStrategy {
    Flat = "SP_CONV_FEE_FLAT",
    Percent = "SP_CONV_FEE_PERCENT",
    FlatAndPercent = "SP_CONV_FEE_FLAT_AND_PERCENT",
    LesserOfFlatAndPercent = "SP_CONV_FEE_LESSER_OF",
    GreaterOfFlatAndPercent = "SP_CONV_FEE_GREATER_OF"
}

export enum DeliveryFee3PStrategy {
    Flat = "3P_DELIVERY_FEE_FLAT",
    Percent = "3P_DELIVERY_FEE_PERCENT",
    FlatAndPercent = "3P_DELIVERY_FEE_FLAT_AND_PERCENT",
    LesserOfFlatAndPercent = "3P_DELIVERY_FEE_LESSER_OF",
    GreaterOfFlatAndPercent = "3P_DELIVERY_FEE_GREATER_OF"
}

export type FeePolicyStrategy =
    | SnackpassConvenienceFeeStrategy
    | DeliveryFee3PStrategy;

export type FeePolicy = {
    // TODO: This really should be ReservedFeeEnum, yes?
    name: string;
    payer: FeePolicyPayer;
    recipient: FeePolicyRecipient;
    flat: number;
    percent: number;
    isTaxable?: boolean;
    rules: FeePolicyRules;

    // Optional tax-rate override for this specific fee
    taxRate?: number;

    // Named strategy to apply to fee calculation
    strategy?: FeePolicyStrategy;
};

export interface DefaultCommissionPolicy {
    fixed: number;
    percent: number;
}

export interface CustomPolicy {
    fulfillment: Fulfillment;
    transactionSource: TransactionSource;
}

export interface ICommissionPolicy extends CustomPolicy {
    fixed: number;
    percent: number;
}

export type IConvenienceFeePolicy = {
    transactionSource: TransactionSource;
    value: number;
};

export type Geolocation = {
    type: string;
    coordinates: [number, number];
};

export type Polygon = {
    type: "Polygon";
    coordinates: number[][][];
};

export interface IStoreEmbedded {
    _id: string;
    name: string;
    color: string;
    emoji: string;
}

export interface IProductCategory {
    name: string;
    productIds: string[];
    _id: string;
    /** @deprecated This was never implemented, DO NOT USE. */
    isArchived?: Date | null;
    // if not null, this is a category representing only those special
    // product types. You cannot mix special product categories with other products or other special categories.
    specialProductCategory?: SpecialProductCategory;
}

export interface IPromotionSummary {
    promotionSummary: string[];
    details: {
        totalUses: number;
        topPromotion: IPromotion;
    };
}

interface SocialMediaPage {
    source: "facebook" | "twitter" | "instagram";
    url: string;
}

enum SalesPriority {
    Fish = 1,
    Dolphin = 2,
    Whale = 3
}

export enum DefaultTipType {
    Flat = "FLAT",
    Percent = "PERCENT"
}

export enum WaitTimeTypeEnum {
    Dynamic = "DYNAMIC",
    Manual = "MANUAL"
}

export enum ChainFeatures {
    "ChainWidePunchcard" = "CHAIN_WIDE_PUNCHCARD",
    "ChainWideGiftCard" = "CHAIN_WIDE_GIFT_CARD"
}

export type ChainConfig = {
    feature: ChainFeatures;
    enabledDate: Date;
};

export type StoreKind = "food_truck" | "brick_and_mortar" | "other";

export const StoreKindDisplay: Record<StoreKind, string> = {
    food_truck: "Food truck",
    brick_and_mortar: "Brick and mortar",
    other: "Other"
};

/**
 * For supporting different order status management flows:
 *     - Manual Accept + Manual Complete
 *     - Auto Accept + Manual Complete
 *     - Manual Accept + Auto Complete
 *     - Auto Accept + Auto Complete
 */
export enum OrderStatusConfiguration {
    ManualAcceptManualComplete,
    AutoAcceptManualComplete,
    ManualAcceptAutoComplete,
    AutoAcceptAutoComplete
}

export interface IStore extends IDocumentWithTimestamps {
    _id: ObjectId;
    name: string;
    slug?: string;
    onboardingType?: "self-serve" | "not-self-serve" | "default";
    onboardingComplete?: boolean;
    address: string;
    addressComponents: IAddress;
    kind?: StoreKind;

    // Branding
    /**
     * The URL pointing to the asset used for splash screen
     */
    brandSplashUrl?: string | null;

    /**
     * whether add "TAP TO ORDER" text overlay to a splash screen video
     */
    hasBrandSplashOverlayText?: boolean;
    /**
     * The store's default color used in all surfaces
     */
    brandColor?: string | null;

    /**
     * The URL pointing to the asset used for customer pickup screen marketing
     */
    brandCustomerPickupScreenMarketingUrl?: string | null;

    /** @deprecated use brandColor instead */
    color?: string | null;

    /**
     * The store's chosen emoji
     */
    emoji: string;

    /** @deprecated use thumbnailUrl instead*/
    coverPhoto?: string | null;
    /**
     * The URL pointing to an image used as the store's primary image, show next to cover image on OO
     * Note: currently falls back to the 1st menu item w/ photo.
     */
    primaryImage?: string | null;
    /**
     * The URL pointing to an image used as the store's secondary image, show next to primary image on OO
     * Note: currently falls back to the 2nd menu item w/ photo.
     */
    secondaryImage?: string | null;
    /**
     * If set to true, this will hide all branding images on OO, including the cover photo, primary, and secondary images.
     */
    hideBrandingImagesOnOO?: boolean | null;

    /**
     * whether to use a corporate map for online ordering
     */
    useCorporateMap?: boolean;
    mapIcon?: string | null;

    // End of Branding
    disablePopularSectionOnOO?: boolean;
    externalReviewUrl?: string | null;
    hours: IHoursSchema;
    region: string;
    pod?: string | null;
    chainId?: ObjectId | null;
    chainConfig?: ChainConfig[];
    hasGifting: boolean;
    noNotes: boolean;
    pickupMin: number | null;
    pickupRange?: number;

    // Tips
    showTipOnPickup: boolean;
    showNoTip?: boolean;
    defaultTipping: boolean;
    defaultTip: {
        defaultTipType: DefaultTipType;
        defaultTips: number[];
        defaultTipValue: number;
    };
    defaultTipPercentage: number; // deprecated

    salesforceId?: string | null;
    isPrimaryStore?: boolean;
    isEnterprise?: boolean;

    // TODO(seankwalker): remove these once deprecated
    hasConvenienceFee: boolean;
    convenienceFee: number;

    howConfirmOrders?: string;
    customCreditCardFee?: boolean;
    digitalMenuEnabled?: boolean;

    hasSpecialDeliveryHours: boolean;
    phoneNumber?: string | null;
    tabletUseQuietTone: boolean;
    primaryStoreTablet?: string | null;
    autoWakeStoreTablet: boolean;

    hasKiosk?: boolean;
    stripeLocationId?: string | null;
    /** Represents an ID to a Stripe configuration used to set custom tips and splash screen on smart readers. */
    stripeReaderConfigurationId?: string | null;

    recurringPaymentsIds?: {
        stripe: string | null;
    };

    dashboardPreferences?: {
        bypassRecurringPaymentsRestriction: boolean;
    };

    hasKds: boolean;
    kdsSkipInProgress: boolean;
    kioskPreferences: {
        customerIdentifierType: KioskCustomerIdentifier;
        fulfillmentTypes: KioskFulfillment[];
        tipDisplay: KioskTipDisplay; // deprecated
        rewardsEnabled: boolean;
        requirePhoneInput: boolean;
        attractScreenUrl: string | null | undefined;
        customWayfindingMessage: string | null | undefined;
        disablePopularSection: boolean | null | undefined;
        blacklistedRewardPhoneNumbers?: string[] | null;
        menuDisplayType?: KioskMenuDisplayType;
    };

    registerRewardsEnabled: boolean;

    tipImageUrl?: string;

    isLive: boolean;
    closedUntil?: Date | null;
    closedUntilReason?: string | null;
    rushHourUntil?: Date | null;
    rushHourDelay?: number | null;

    defaultCommissionPolicy: DefaultCommissionPolicy;
    customCommissions?: boolean;
    customCommissionPolicies: ICommissionPolicy[];

    /** @deprecated use defaultCommissionPolicy.fixed instead */
    commissionFixed?: number;

    /** @deprecated use defaultCommissionPolicy.percent instead */
    commissionPercent?: number;

    chargeFeePolicy: IChargeFeePolicy;
    customChargeFeePolicies: IChargeFeePolicy[];

    convenienceFeePolicies: IConvenienceFeePolicy[];

    /** @deprecated in favor of feePolicies */
    customFeePolicies: FeePolicy[];

    feePolicies?: FeePolicy[];

    customerServiceText: string;
    pictureMenuProductIds: string[];
    dineInPreferences: {
        hasDineIn?: boolean;
        serviceType?: "self_service" | null;
        directions?: string | null;
        requireTableNumber?: boolean;
    };
    languages: Language[];
    pos: {
        disable?: boolean;
        disableFlashAnimations?: boolean;
        disableRefund?: boolean;
        disableSoldOut?: boolean;
    };
    shouldRestartPOS?: boolean;
    allowPauseOnPOS?: boolean;
    allowPauseRestOfDayOnPOS?: boolean;
    aggressiveBatteryModeOnPOS?: boolean;
    posAutoAcceptsOrders?: boolean;
    serverAutoAcceptsOrders?: boolean;
    pickupTimeType?: string;
    defaultPickupTime?: number;
    defaultPickupMinTime?: number;
    defaultPickupMaxTime?: number;
    waitTimeType?: WaitTimeTypeEnum;

    hasTabDineIn?: boolean;
    tabDineInPreferences?: {
        allowCashPayments?: boolean;
    };

    // online ordering and restaurant home page
    onlineOrderingEnabled: boolean;
    snackpassAppEnabled?: boolean;
    kioskEnabled?: boolean;
    registerEnabled?: boolean;
    paymentsEnabled?: boolean;
    /**
     * Represents that this store has the gift cards feature enabled.
     *
     * NB: This **does not** mean the store has added digital and/or physical gift cards.
     */
    hasGiftCardsEnabled?: boolean;
    kdsEnabled?: boolean;
    cateringEnabled?: boolean;
    websiteEnabled?: boolean;
    customerPickupScreenEnabled?: boolean;
    deliveryMarketplaceIntegrationEnabled?: boolean;
    timeAndAttendanceEnabled?: boolean;
    customBrandedAppEnabled?: boolean;
    dynamicMenuBoardEnabled?: boolean;
    onlineOrderingId: number;
    thumbnailUrl?: string | null;
    logoUrl?: string | null;

    // Catering fields
    catering: {
        allowDelivery: boolean;
        orderMinimum: number;
        minLeadTime: number;
        productCategories: IProductCategory[];
        email?: string | null;
    };

    aboutUs?: string | null;

    accountManager?: ObjectId | null;
    accountExecutive?: ObjectId | null;
    numFreeTrialPurchases?: number;
    shouldCheckFreeTrial?: boolean;
    imageContent: string[];
    socialMediaPages: SocialMediaPage[];

    // alerts
    alerts: {
        email: boolean;
        fax: boolean;
        phone: boolean;
    };
    // Used to alert stores for new delivery orders
    // In the future, can be refactored to a nicer abstraction of
    // contacts and preferences, similar to what's on the comms framework.
    deliveryOnlyEmail?: string | null;

    // fulfillment info
    pickup: boolean;
    pickupTime: number;
    defaultFulfillment?: FulfillmentTypeEnum | null; // The default fulfillment method will be pre-selected when checking out in client app
    collectTip?: boolean; // if set to false, in all client app not show the tipping options

    // delivery
    delivery: boolean;
    deliveryFee: number;
    deliveryMin: number;
    deliveryTime: string | null;
    deliveryRanges: IDeliveryRange[];
    deliveryZone?: Polygon;

    email?: string | null;
    faxNumber?: string | null;
    geolocation: Geolocation;

    // revenues, taxes
    taxRate: number;
    specifyTaxRateByFulfillment: boolean;
    taxRateDineIn?: number;
    taxRatePickup?: number;
    taxRateDelivery?: number;
    doesNotRemitTax?: boolean;

    test: boolean;
    hasTablet: boolean;
    tabletVersion: string | null;

    lastPing: Date;
    allowViewEarningsOnTablet: boolean;
    integrations: IIntegration;

    posIntegrations?: IPOSIntegration;
    // Virtuals
    tabletOffline: boolean;
    isOpen: boolean;
    isOpenAt: (date: Date) => boolean;
    storeOffersDelivery?: boolean;
    productCategories: IProductCategory[];
    promotions: IPromotion[];
    payInStore: boolean;
    requestStoreToJoinSnackpass?: boolean;
    customDeliveryTimes: string[];
    customPickUpTimes: string[];
    pickupDirections?: string | null;
    notes?: string;
    specialDeliveryHours: IHoursSchema | null;
    products?: IProduct[];
    isArchived: boolean;
    isTestStore?: boolean;
    initialReceiptNumber?: number;
    monthlyFee: number;
    tags: ITag[];
    hoursDescription: string;
    hasChainWidePunchcard?: boolean;
    hasChainWideGiftCard?: boolean;
    defaultBagInCart?: number; //if set to 1, by default one bag will be added to the cart of all purchases
    // Virtual to find if a store currently offers delivery
    isDeliveryAvailable: boolean;

    // Note: this is used to determine if the store shows up in the app, not if the store
    // has pickup now enabled. that is the hasPickup
    allowOrderNow?: boolean;
    hasPickupNow: boolean;
    // Scheduled order fields
    hasScheduledOrders: boolean;
    hasBatching?: boolean;
    scheduleAheadInterval?: number;
    scheduleAheadBatchSize?: number;
    /*
     * add a buffer time to the first available timeslot displayed to users for scheduling ahead orders.
     */
    scheduleAheadFirstTimeSlotBuffer?: number;

    /*
     *  Orders cannot be scheduled within this time frame in hours.
     */
    scheduledOrderMinLeadTime: number;
    scheduledOrderPosTriggerTime?: number | null;

    comingSoon?: boolean;
    dateLiveOnSnackpass?: Date;
    pickupTimeStats: {
        numPurchases?: number | null;
        avgPickupTime?: number | null;
    };
    referralChannels: ObjectId[] | IUser[]; // depends on if population happens
    referralConversionText?: string; // displayed on OO/Kiosk referral screens/modals
    menuNotes?: string;
    salesPriority?: SalesPriority;
    purchaseCount?: {
        allTime: number;
        pastTwoWeeks?: number;
        yesterday?: number;
    };
    // Used to determine if a store offers their own 1P delivery
    storeDelivers?: boolean;

    payoutPolicy: PayoutPolicy;
    pendingPayoutPolicy?: PayoutPolicy | null;
    payoutChannel?: PayoutChannel;

    profilePictureUrl?: string;
    hasEmployeeAuditTracking?: boolean;
    hasServerDirectPrint?: boolean;
    attendanceSettings?: IAttendanceSettings;

    manualThirdPartySources?: ThirdPartySource[];

    /**
     * For supporting different order status management flows:
     *     1. Manual Accept + Manual Complete
     *     2. Auto Accept + Manual Complete
     *     3. Manual Accept + Auto Complete
     *     4. Auto Accept + Auto Complete
     */
    orderStatusConfiguration: OrderStatusConfiguration;
    // send slack message to #forge-store-status when a store starts receiving the first order after the “live” toggle is turned on
    notifyOnFirstOrder?: boolean;

    /**
     * If set, this is the minimum amount that must be spent to place an order
     * (unless it is free). Always a positive integer if defined.
     * If unset, the minimum is $0.50.
     */
    minimumChargeAmountCents?: number;

    /**
     * Whether to allow gift cards as a payment method, since a customer may have a gift card.
     */
    hasPhysicalGiftCardEverBeenEnabled?: boolean;

    /**
     * Whether the store has a physical gift card available for purchase.
     */
    physicalGiftCardAvailable?: boolean;

    /**
     * Whether the store has a digital gift card available for purchase.
     */
    digitalGiftCardAvailable?: boolean;

    /**
     * loyaltyEmoji is meant to symbolize the store's loyalty program
     */
    loyaltyEmoji?: string;
    /**
     * loyaltyCustomName represents the custom name chosen by the restaurant for their loyalty program,
     * allowing them to personalize the program's name, such as "points" or "stars".
     */
    loyaltyCustomName?: string;
    /**
     * loyaltyCardBackgroundImage is the custom background image chosen by the restaurant for their loyalty program,
     * allowing them to personalize the loyalty card.
     */
    loyaltyCardBackgroundImage?: string;

    /**
     * skipOtherPaymentConfirmation skips confirmation on Order Hub for other payment types. The use case of this field is for deli restaurants
     * that don't operate with an order hub and would like to send these payment types directly to the KDS
     */
    skipOtherPaymentConfirmation?: boolean;

    /**
     * Stores use this as an announcement to customers on their receipt. This is arbitrary text, so the original use case
     * is to link to a google survey.
     */
    smartReceiptCustomerMessage?: string;

    /**
     * Whether employees are able to see tips (on register)
     */
    hideTipsFromEmployees?: boolean;

    /**
     * Whether this store should use the new multi-location menu.
     */
    useMulMenu?: boolean;

    /**
     * A default pin that store devices can use to protect employee only screens
     * from being accessed by customers even if pin auditing/entry is off
     */
    defaultPin?: string;

    /**
     * If true, then this store can use offline mode within register
     */
    isOfflineModeEligible?: boolean;

    /**
     * Overrides the normal label and descriptions for fees
     */
    customFeeLabels?: {
        snackpassConvenienceLabel?: string; // Label for SnackpassConvenienceFee (currently Operating Fee)
        snackpassConvenienceDescription?: string; // Description for SnackpassConvenienceFee (currently Charged by our service provider...)
        convenienceStoreLabel?: string; // Label for ConvenienceStoreFee (currently Service Fee)
        convenienceStoreDescription?: string; // Description for ConvenienceStoreFee (currently Set by our restaurant to cover...)
        tipLabelPrefix?: string; // Prefix in the non-delivery tip case. If used it will be rendered as `${tipLabelPrefix} ${storeName}`. Currently tipLabelPrefix is "100% of your tip supports"
    };

    /** List of currently active storeMenus, by ID */
    activeMenus?: ObjectId[];

    /** Whether or not a store should send the default menu (all items+categories) */
    disableDefaultMenu?: boolean;
}

export interface IDeliveryRange {
    start: number;
    end: number;
    deliveryFee: number;
    deliveryMin: number;
    feePolicies?: FeePolicy[];
}

export type IStore_Public = Pick<
    IStore,
    | "_id"
    | "onlineOrderingId"
    | "name"
    | "slug"
    | "phoneNumber"
    | "address"
    | "kind"
    | "brandColor"
    | "color"
    | "emoji"
    | "closedUntil"
    | "hasConvenienceFee"
    | "convenienceFee"
    | "delivery"
    | "deliveryFee"
    | "deliveryMin"
    | "deliveryTime"
    | "deliveryRanges"
    | "hasSpecialDeliveryHours"
    | "specialDeliveryHours"
    | "email"
    | "deliveryOnlyEmail"
    | "faxNumber"
    | "geolocation"
    | "hours"
    | "hoursDescription"
    | "region"
    | "pod"
    | "tags"
    | "pickupTime"
    | "taxRate"
    | "test"
    | "isLive"
    | "tabletOffline"
    | "isOpen"
    | "isOpenAt"
    | "isDeliveryAvailable"
    | "payInStore"
    | "onlineOrderingEnabled"
    | "aboutUs"
    | "imageContent"
    | "socialMediaPages"
    | "pictureMenuProductIds"
    | "comingSoon"
    | "coverPhoto"
    | "rushHourUntil"
    | "showTipOnPickup"
    | "noNotes"
    | "pickupMin"
    | "catering"
    | "thumbnailUrl"
    | "logoUrl"
    | "scheduledOrderMinLeadTime"
    | "chainId"
    | "chainConfig"
    | "hasChainWidePunchcard"
    | "hasChainWideGiftCard"
    | "pickupRange"
    | "deliveryZone"
    | "menuNotes"
    | "purchaseCount"
    | "defaultTipping"
    | "defaultTipPercentage"
    | "isPrimaryStore"
    | "hasTabDineIn"
    | "tabDineInPreferences"
    | "defaultFulfillment"
    | "defaultBagInCart"
    | "collectTip"
    | "orderStatusConfiguration"
    | "notifyOnFirstOrder"
    | "minimumChargeAmountCents"
    | "hasPhysicalGiftCardEverBeenEnabled"
    | "loyaltyEmoji"
    | "loyaltyCustomName"
    | "loyaltyCardBackgroundImage"
    | "loyaltyCardBackgroundImage"
    | "primaryImage"
    | "secondaryImage"
    | "hideBrandingImagesOnOO"
    | "skipOtherPaymentConfirmation"
    | "smartReceiptCustomerMessage"
    | "hideTipsFromEmployees"
    | "waitTimeType"
    | "hasBrandSplashOverlayText"
    | "useCorporateMap"
    | "mapIcon"
    | "disablePopularSectionOnOO"
    | "externalReviewUrl"
    | "customFeeLabels"
>;
