import {
    Accounting,
    EmployeeAuditEvent,
    Geolocation,
    IThirdPartyCharge,
    TaxPolicy,
    WeightUnitTypes
} from ".";
import {
    IFixedChargeFeePolicy,
    IPassthroughChargeFeePolicy
} from "./ChargeFeePolicies";
import {
    IBalanceAdjustment,
    ICheckoutCharge,
    IInStoreCharge,
    IPaypalCharge,
    IStripeCharge,
    IUntrackedStripeCharge,
    IZageCharge,
    ThirdPartySource,
    ThirdPartySourceType
} from "./ChargeRecord";
import {
    IGiftCardCreditRecord,
    IGlobalCreditRecord,
    IStoreCreditRecord
} from "./CreditRecord";
import { DeliveryIntegrationProvider } from "./Delivery";
import { IDocumentWithTimestamps, ObjectId } from "./globals";
import {
    IGiftCardCreditRefund,
    IGlobalCreditRefund,
    IPaymentRefund,
    IStoreCreditRefund,
    IUntrackedRefund
} from "./RefundRecord";
import { SalesTaxRemitter } from "./Region";
import { IReward } from "./Rewards";
import { FeePolicy } from "./Store";
import { SalesTax } from "./sub/SalesTax";
import { Maybe } from "../utils/types";
import { IPromotion } from "./Promotion";
import { RewardPromotionType } from "./RewardPromotion";
import { Special, SpecialAdjustment } from "./Specials";

export type Fulfillment = "PICKUP" | "DELIVERY" | "DINE_IN";

export enum CartAdjustmentType {
    DiscountFlat = "DISCOUNT_FLAT",
    DiscountPercent = "DISCOUNT_PERCENT",
    SurchargeFlat = "SURCHARGE_FLAT"
}

export type CartAdjustment = {
    uuid: string;
    type: CartAdjustmentType;
    flat?: number;
    percent?: number;
    notes?: string;

    /** Tax rate override. If not set, use Purchase.salesTaxRate */
    taxRate?: number;

    /**
     * Taxable amount of this adjustment. If not set, this `flat` for
     * SurchargeFlat with `taxRate` > 0. Otherwise, it's 0.
     */
    taxableAmount?: number;

    /**
     * Tax-exempt amount of this adjustment. If not set, use `flat` for
     * SurchargeFlat with `taxRate` = 0. Otherwise, it's 0.
     */
    taxExemptAmount?: number;

    /**
     * Tax amount of this adjustment. If not set, use `flat` * `taxRate` for
     * SurchargeFlat, otherwise it's 0.
     */
    taxAmount?: number;
};

export enum FulfillmentTypeEnum {
    Pickup = "PICKUP",
    Delivery = "DELIVERY",
    DineIn = "DINE_IN"
}

export declare type KioskPaymentProvider = "stripe";

export enum PaymentProvider {
    stripe = "stripe",
    checkout = "checkout",
    paypal = "paypal",
    adjustment = "adjustment",
    inStore = "inStore",
    square = "square",
    ocmp = "ocmp",
    thirdParty = "thirdParty",
    zage = "zage",
    giftCards = "giftCards",
    unpaid = "unpaid" // This is an interim value for an incomplete purchase until payment details are attached
}

export type TransactionSource = "app" | "kiosk" | "online" | "thirdParty";

export type PurchaseReportTransactionSource = TransactionSource | "register";

export enum TransactionSourceTypeEnum {
    App = "app",
    Kiosk = "kiosk",
    Online = "online",
    ThirdParty = "thirdParty"
}

export enum PurchaseReportTransactionSourceTypeEnum {
    App = "app",
    Kiosk = "kiosk",
    Online = "online",
    ThirdParty = "thirdParty",
    Register = "register"
}

export type ComplimentValue = "FRIENDLY_STAFF" | "FAST_PICKUP" | "AMAZING_FOOD";
export type ComplimentLabel = "Friendly Staff" | "Fast Pickup" | "Amazing Food";
export type Compliment = { value: ComplimentValue; label: ComplimentLabel };

export type ComplaintValue =
    | "NOT_READY"
    | "INCORRECT_ORDER"
    | "FOOD_QUALITY"
    | "BAD_SERVICE"
    | "LATE_DELIVERY"
    | "APP"
    | "OTHER"
    | "DID_NOT_START";
export type ComplaintLabel =
    | "Food wasn't ready on time"
    | "Order wasn't correct"
    | "Food quality"
    | "Bad service"
    | "Late delivery"
    | "Problems with app"
    | "Other"
    | "Did not start order";
export type Complaint = { value: ComplaintValue; label: ComplaintLabel };

export enum PaymentType {
    apple = "APPLE",
    credit = "CREDIT/DEBIT",
    bank = "BANK",
    preload = "PRELOAD",
    paypal = "PAYPAL",
    inStore = "IN STORE",
    cash = "CASH",
    giftCards = "GIFTCARDS",
    other = "OTHER"
}

export enum InStorePaymentType {
    cash = PaymentType.cash,
    giftCards = PaymentType.giftCards,
    other = PaymentType.other
}

export enum PurchaseStatus {
    received = "RECEIVED",
    started = "STARTED",
    canceled = "CANCELED",
    completed = "COMPLETED"
}

export enum KdsStatusType {
    queued = "QUEUED",
    started = "STARTED",
    finished = "FINISHED",
    pickedUp = "PICKED_UP"
}

export enum KdsItemStatusType {
    notStarted = "NOT_STARTED",
    inProgress = "IN_PROGRESS",
    completed = "COMPLETED"
}

export enum CateringStatusType {
    unconfirmed = "unconfirmed",
    confirmed = "confirmed",
    rejected = "rejected"
}

export interface ISelectedAddon {
    _id: string;
    name: string | null;
    price: number | null;
    priceAfterDiscount: number | null;
    quantity: number;
    addonGroup: {
        _id: string;
        name: string | null;
    };
}

export interface IOrderItemProduct {
    _id: string;
    name: string;
    description: string;
    category: string;
    categoryId?: string;
    priceByWeight?: { unit: WeightUnitTypes; perUnit: number } | null;
}

/**
 * NOTE: Previous interface definition for rewardPromotion + promotion,
 * removed in favor of the defined interfaces in the RewardPromotion.ts
 * and Promotion.ts files.

export interface IOrderItemPromotion {
    _id: string;
    discount: any;
    name: string;
    nameForStore: string;
    type: PromoType;
    tabletShowsPromotionDiscount: boolean;
}
 */

/**
 * NOTE: Previous interface definition for reward, removed in favor
 * of the defined interface in Reward.ts file.

export type IOrderItemReward = Pick<
    IReward,
    | "_id"
    | "name"
    | "discount"
    | "fromPromotion"
    | "tabletShowsRewardDiscount"
    | "purchaseId"
>;
 */

/** A subset of fields from `IPromotion` stored in other collections (e.g. `IPurchase#items#promotion`) */
export type EmbeddedPromotion = Pick<
    IPromotion,
    | "_id"
    | "discount"
    | "name"
    | "nameForStore"
    | "type"
    | "tabletShowsPromotionDiscount"
    | "shouldDiscountAddons"
    | "applyTaxToSnackpassContribution"
> & {
    accounting: Pick<
        Accounting,
        "voidCommissionOnPurchase" | "commissionVoidItem"
    >;
};

/**
 * A subset of field from `IPromotion` stored in other collections.
 * Note that this is a different type than `EmbeddedPromotion` because
 * it includes a `type` field with special reward promotion types.
 */
export interface EmbeddedRewardPromotion
    extends Omit<EmbeddedPromotion, "type"> {
    type: RewardPromotionType;
}

export interface IOrderItem {
    _id?: string;
    basePrice: number;
    basePriceAfterDiscount: number;
    addonsPrice: number;
    addonsPriceAfterDiscount: number;
    totalPrice: number;
    totalPriceAfterDiscount: number;
    points: number;
    notes: string | null;
    product: IOrderItemProduct;
    weight?: { amount: number; unit: WeightUnitTypes } | null;
    usingPromotion: boolean;
    usingReward: boolean;
    promotion: EmbeddedPromotion | null;
    reward: IReward | null;
    menuId?: string;
    rewardPromotion: EmbeddedRewardPromotion | null;
    selectedAddons: ISelectedAddon[];
    groupUuid?: string;
    kdsItemStatus?: KdsItemStatusType;
    taxInfo?: SalesTax | null;
    appliedTaxPolicies?: TaxPolicy[];
    taxAmount?: number;
    taxExemptAmount?: number;
    taxableAmount?: number;
    refunded?: boolean;
}

export type Status = {
    type: PurchaseStatus;
    createdAt: Date;
    updatedAt: Date;
};

export type KdsStatus = {
    type: KdsStatusType;
    createdAt: Date;
    updatedAt: Date;
};

export declare type GiftCardUsed = {
    giftCardId: ObjectId;
    amountInCents: number;
    amountRemainingInCents?: number;
    giftCardStoreId?: ObjectId;
    wasAddedToPayrollOnCreation?: boolean;
};

export type ChargeFeeInDollars = {
    _id: string;
    flat: number;
    percent: number;
    total: number;
};

export type CustomFeeInDollars = {
    name: string;
    total: number;
};

export type UserPurchasing = {
    _id: string;
    email?: string;
    name: string | null;
    number: string;
    countryCode?: string;
    address: string;
};

export type StorePurchasedAt = {
    _id: string;
    deliveryTime: string;
    emoji: string;
    name: string;
    phoneNumber: string;
    address: string;
    color: string;
    email: string;
    payInStore: boolean;
    chargeFeePolicy: IFixedChargeFeePolicy | IPassthroughChargeFeePolicy;
    isPrimaryStore?: boolean;
};

export type FoodHallPurchasedAt = {
    chainId: string;
    name: string;
};

export type PickupEstimate = {
    storeId: string;
    prediction: number;
    itemCount: number;
    month: number;
    dayOfWeek: number;
    hourOfDay: number;
    concurrentOrders: number;
    meanStorePickupTime: number;
    timeToServeInference: number;
};

export enum DeliveryStatus {
    // courier not assigned yet
    Pending = "PENDING",
    // restaurant has started order
    Started = "STARTED",
    // driver is on the way to pickup
    Pickup = "PICKUP",
    // driver has picked up the order
    PickupComplete = "PICKUP_COMPLETE",
    // order dropped off w/ customer
    Delivered = "DELIVERED",
    Canceled = "CANCELED",
    // order was returned to restaurant
    Returned = "RETURNED"
}

export type DeliveryMetadata = {
    provider: DeliveryIntegrationProvider;
    deliveryId?: string;
    quoteId?: string;
    status?: DeliveryStatus | null;
    pickupEta?: Date | null;
    dropoffEta?: Date | null;
    initialDropoffEta?: Date | null;
    trackingUrl?: string | null;
    isLegacy?: boolean; // so we know if the client is legacy
};

// these will be "buckets" from tax jar
export enum PurchaseFeeEnum {
    Custom = "CUSTOM",
    Delivery = "DELIVERY"
}

export interface IPurchaseFee {
    type: PurchaseFeeEnum;
    fee: FeePolicy;
    total: number;
    taxInfo?: SalesTax | null;
    taxAmount?: number;
    taxExemptAmount?: number;
    taxableAmount?: number;
}

export type ThirdPartyUser = {
    email?: string;
    name: string;
    number?: string;
    address?: string;
};

export type ThirdPartyOrderInfo = {
    deliverect?: {
        orderId: string;
        channelOrderDisplayId: string;
        channel: string;
        user?: ThirdPartyUser;
        deliveryNote?: string;
        serviceCharge?: number;
        isAsap?: boolean;
    };
    chowly?: {
        orderId: string;
        user?: ThirdPartyUser;
    };
};

export type PrepStationStatus = {
    name: string;
    isCompleted: boolean;
    completedAt?: Date | null;
    prepStation: string;
};

export interface IPurchase extends IDocumentWithTimestamps {
    _id: string;
    accountManager?: string;
    isTestPurchase?: boolean;
    firebaseKey?: string;
    transactionSource?: TransactionSource;
    isOnlineOrder?: boolean;
    isScheduledOrder?: boolean;
    isBatched?: boolean;
    isTaxExempt?: boolean;
    scheduledDate?: Date;
    partyId?: string | null;
    partyPOSName?: string | null;
    amountDueToStore?: number;
    amountPaidByCustomer?: number;
    amountDiscounted?: number;
    snackpassContribution?: number;
    taxableSnackpassContribution?: number;
    snackpassTaxContribution?: number;
    commission?: number;
    commissionRate?: number;
    commissionFlat?: number;
    paymentProviderChargeFee: ChargeFeeInDollars;
    /**
     * The `Account` a purchase is associated with. Any resulting balances
     * owed to/by the store are tied to this account, even if the store
     * later changes accounts.
     */
    account?: string;
    storeChargeFee: ChargeFeeInDollars;
    customFees?: CustomFeeInDollars[];

    // FIXME: don't cache the fee values but for now that is fine
    customerFees?: number;
    storeFeesCredited?: number;
    storeFeesDebited?: number;
    fees?: IPurchaseFee[];

    snackpassCreditCardLoss: number;
    snackpassRevenue: number;
    convenienceFee?: number;
    creditCardFlat?: number;
    creditCardPercent?: number;
    creditCardFees?: number;
    salesTaxRate?: number;
    salesTaxAmount?: number;
    salesTaxWithheld?: number;
    tip?: number;
    deliveryFee?: number;
    faxFee?: number;
    deliveryAddress?: string | null;
    deliveryInfo?: DeliveryMetadata | null;
    subtotal?: number;
    taxRemitter: SalesTaxRemitter;
    grossSalesAmount?: number;
    taxExemptSalesAmount?: number;
    taxToBeRemittedForSnackpass?: number;
    taxToBeRemittedForStore?: number;
    paymentProviderId?: PaymentProvider;
    chargeId?: string | null;
    charges: (
        | IStripeCharge
        | ICheckoutCharge
        | IPaypalCharge
        | IUntrackedStripeCharge
        | IBalanceAdjustment
        | IInStoreCharge
        | IThirdPartyCharge
        | IZageCharge
    )[];
    credits: (
        | IGlobalCreditRecord
        | IStoreCreditRecord
        | IGiftCardCreditRecord
    )[];
    customerId?: string | null;
    refundId?: string | null;
    paymentMethod?: PaymentType;
    paypalTransactionId?: string | null;
    paypalInvoiceNumber?: string | null;

    thirdPartyOrderId?: string | null;
    customerName?: string | null;
    user?: UserPurchasing | null;

    globalCreditUsed?: number;
    storeCreditUsed?: number;
    giftCardsCreditUsed?: number;
    giftCardsUsed?: GiftCardUsed[];
    status: Status[];
    /** A flag representing that the purchase has been received by an in-store device. */
    received: boolean;
    kdsStatus: KdsStatus[];
    startTime?: Date | null;
    pickupTime?: Date | null;
    endTime?: Date | null;
    pickupTimeDuration?: number | null;
    delayDuration?: number | null;
    finishedEarly?: boolean;
    isDelayed?: boolean;
    fulfillment?: Fulfillment;
    items: IOrderItem[];
    numberOfBags?: number | null;
    receiptNumber?: number | null;
    notes?: string | null;
    rating?: "GOOD" | "BAD" | null;
    complaints: Complaint[];
    compliments: Compliment[];
    complaintNotes?: string | null;
    complimentNotes?: string | null;
    pointsEarned: number;
    store: StorePurchasedAt | null;

    region?: string;
    geolocation: Geolocation;
    refund?: boolean;
    partialRefund?: boolean;
    refundReason?: string;
    refundedAmount?: number;
    refundedProductIds: string[];
    refunds: Array<
        | IPaymentRefund
        | IStoreCreditRefund
        | IGiftCardCreditRefund
        | IGlobalCreditRefund
        | IUntrackedRefund
    >;
    dispute?: boolean;
    disputeId?: string | null;
    disputeDate?: Date | null;
    submittedDisputeEvidence?: boolean;
    upCharge?: boolean;
    upChargeAmount?: number;
    upChargedProductIds: string[];
    isSpecialGift?: boolean;
    specialGiftState?: "PURCHASED" | "REDEEMED" | null;
    isFreeTrialPurchase?: boolean;
    purchaseLiveAt?: Date;
    batchKey?: string;
    notificationsSent?: boolean;
    pickupEstimate?: PickupEstimate;

    catering: {
        isCatering: boolean;
        headcount: number;
        status: CateringStatusType;
    };

    _schemaVersion: number;

    // Virtuals
    usesGlobalCredit?: boolean;
    usesStoreCredit?: boolean;
    hasBalance?: boolean;
    receiptToken?: string; // allows unauthenticated users to view this purchase in Smart Receipt

    // Dine-in
    tableNumber?: string | null;

    // MultiCart/FoodHalls
    groupId?: string | null;
    foodHall?: FoodHallPurchasedAt | null;

    // Cash
    cashTotal?: number;
    hasPaidCash?: boolean;

    // Charge types
    inStorePaymentType?: InStorePaymentType;
    thirdPartySource?: ThirdPartySource;
    thirdPartySourceType?: ThirdPartySourceType;
    thirdPartyOrderInfo?: ThirdPartyOrderInfo;
    isManualCardPurchase?: boolean;

    kioskSerial?: string | null;
    isPOSMode?: boolean;

    // Employee Management System
    auditEvents: Maybe<EmployeeAuditEvent[]>;

    // Manual Discounts
    cartAdjustments?: CartAdjustment[];

    // Menu dimensions to forward context to @snackpass/menus-sdk
    menu?: {
        hash: string;
        fulfillment: string;
        channel: string;
        temporal: Date;
        langCode: string;
    };

    // Prep Station Completion Statuses
    prepStationStatuses?: PrepStationStatus[];

    // Specials
    specials?: Special[];
    specialAdjustments?: SpecialAdjustment[];
}

// A compact interface for transferring across websockets.
// Safe to be seen by other users in the queue.
export type IPurchase_MinimalQueueStatus = Pick<
    IPurchase,
    | "_id"
    | "status"
    | "pickupTime"
    | "isDelayed"
    | "receiptNumber"
    | "purchaseLiveAt"
    | "createdAt"
> & { firstName: string };
