import * as Yup from "yup";
import {
    IPromotion,
    IStore,
    PromoTypes,
    IDealItem,
    ContributionPolicy,
    Accounting,
    IPartyPromotion,
    PartyPromoTierCondition,
    IPartyPromoTier,
    PromoTarget,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";
import _ from "lodash";
import { transformHoursToFormHoursVersion } from "../Hours";
import {
    SubsidizationCondtionsSelect,
    SubsidizationSelect,
    FormValues,
    DiscountSelect,
    RewardExpirationSelect,
    ContributionPolicyFormVersion,
    AccountingFormVersion,
    DealItemFormVersion
} from "./types";
import { isTruthy } from "../../utils/Helpers";
import { transformFormHoursVersionToHours } from "../Hours";
import { PROMO_CODE_STORE_ID } from "../../utils/Constants";
import validator from "validator";

const urlRegex = require("url-regex");

export const dealItemTemplate: DealItemFormVersion = {
    discount: null,
    discountType: "" as DiscountSelect,
    name: "",
    isDiscounted: true,
    productIds: [],
    categories: []
};
function mapToFormVersion(
    contributionPolicy: ContributionPolicy
): ContributionPolicyFormVersion {
    let isSubsidizedDollars =
        contributionPolicy.snackpassSubsidizationDollars !== null;
    let isSubsidizedPercent =
        contributionPolicy.snackpassSubsidizationPercent !== null;

    let subsidizationType: SubsidizationSelect = "" as SubsidizationSelect;
    if (isSubsidizedDollars) {
        subsidizationType = "dollars";
    }

    if (isSubsidizedPercent) {
        subsidizationType = "percent";
    }

    let subsidizationConditionsType: SubsidizationCondtionsSelect =
        "" as SubsidizationCondtionsSelect;

    if (contributionPolicy.conditions.dollarsDiscounted !== null) {
        subsidizationConditionsType = "dollarsDiscounted";
    }

    if (contributionPolicy.conditions.redemptions !== null) {
        subsidizationConditionsType = "redemptions";
    }

    return {
        ...contributionPolicy,
        subsidizationType,
        subsidizationConditionsType
    };
}

// in the future, if it's not subsidized then contributionPolicies length will
// be 0. This function is to account for legacy promos with a contribution
// policy but subsidization set to 0
export function isSubsidized(accounting: Accounting) {
    if (accounting.contributionPolicies.length === 0) {
        return false;
    }
    if (
        accounting.contributionPolicies[0] &&
        !accounting.contributionPolicies[0].snackpassSubsidizationDollars &&
        !accounting.contributionPolicies[0].snackpassSubsidizationPercent
    ) {
        return false;
    }
    return true;
}

function transformAccounting(accounting: Accounting): AccountingFormVersion {
    return {
        totalAmountSpent: accounting.totalAmountSpent,
        isSubsidized: isSubsidized(accounting),
        commissionVoidItem: accounting.commissionVoidItem,
        voidCommissionOnPurchase: accounting.voidCommissionOnPurchase,
        contributionPolicies:
            accounting.contributionPolicies.map(mapToFormVersion)
    };
}

function mapDealItemToFormVersion(dealItem: IDealItem): DealItemFormVersion {
    let ret: DealItemFormVersion = {
        ...dealItem,
        isDiscounted: dealItem.discount ? true : false,
        discountType: dealItem.discount
            ? (Object.keys(dealItem.discount)[0] as DiscountSelect)
            : ("" as DiscountSelect)
    };
    return ret;
}

function validateHoursStartEnd(value: any) {
    if (!value) {
        return true;
    }
    let localHours = transformFormHoursVersionToHours(value.local);
    for (let range of localHours) {
        if (_.get(range, "end") <= _.get(range, "start")) {
            return false;
        }
    }
    return true;
}

export const contributionPolicyTemplate: ContributionPolicyFormVersion = {
    // One of these two will have a truthy value, and the other will be null.
    // If both subsidization percent and subsidization dollars are null, then subsidization should not occur on the back end.
    snackpassSubsidizationPercent: null,
    snackpassSubsidizationDollars: null,
    subsidizationConditionsType: "" as SubsidizationCondtionsSelect,
    subsidizationType: "" as SubsidizationSelect,
    commissionVoid: false,
    conditions: {
        redemptions: null,
        dollarsDiscounted: null
        // dates is not live feature
        // dates: null
    }
};

export const tiersTemplate: IPartyPromoTier = {
    conditions: {
        conditionType: "NUM_PEOPLE" as PartyPromoTierCondition,
        minPeople: 0,
        maxPeople: null
    },
    discount: {
        percentOff: 10
    }
};

export function getRewardExpirationType(
    promotion: IPromotion
): RewardExpirationSelect {
    if (!promotion.rewardExpiration) {
        return "" as RewardExpirationSelect;
    }
    if (promotion.rewardExpiration.seconds) {
        return "seconds";
    }
    if (promotion.rewardExpiration.date) {
        return "date";
    }
    return "" as RewardExpirationSelect;
}

export function secondsToDay(seconds: number) {
    const SECONDS_IN_DAY = 86400;
    return Math.round(seconds / SECONDS_IN_DAY);
}

// If editing existing form, this function will map the existing fields to the form.
// otherwise, use default values.
// can make this shorter function in the future such as combining the defaultValues const with the return for edit mode
export function initializeFields(
    activeStore: IStore | null,
    promotion?: IPromotion,
    editMode?: boolean
): FormValues {
    const defaultValues: FormValues = {
        name: "",
        marketingName: "",
        nameForStore: "",
        isRunning: false,
        isKioskEligible: false,
        applyTaxToSnackpassContribution: false,
        code: "",
        isMulticode: false,
        codes: [],
        discountType: "" as DiscountSelect,
        rewardExpires: true,
        rewardExpirationType: "" as RewardExpirationSelect,
        rewardExpirationDays: null,
        rewardExpiration: null,
        accounting: {
            totalAmountSpent: {
                snackpass: 0,
                store: 0
            },
            isSubsidized: false,
            commissionVoidItem: false,
            voidCommissionOnPurchase: false,
            contributionPolicies: [contributionPolicyTemplate]
        },
        conditions: {
            cartMin: null,
            cartMax: null,
            pickupOnly: false,
            deliveryOnly: false,
            onePerCart: false
        },
        storeId: activeStore ? activeStore._id : "",
        storeName: activeStore ? activeStore.name : "",
        store: activeStore || null,
        storewide: false,
        type: "" as PromoTypes,
        productIds: [],
        categories: [],
        pointsRequired: 0,
        // deal values
        dealItems: [],
        dealItemsFormVersion: [],
        description: "",
        hasHours: false,
        // even if hasHours is false, set form version to empty Hours object.
        // handleSubmit will handle setting hours to null if hasHours is false.
        hoursFormVersion: {
            zone: SnackpassTimezoneEnum.newYork,
            local: []
        },
        hours: null,
        targetsFormVersion: {
            firstTime: false,
            students: false,
            subscribers: false
        },
        // You do not need to set this manually. It will be set upon submitting
        // the form.
        targets: [],
        clientShowsRemainingUses: false,
        hasActiveTimePeriod: false,
        activeTimePeriod: null,
        voidCommission: false, // Doesn't do anything on the backend currently
        multiuse: false,
        hasMaximumUses: false,
        maximumUses: null,
        shouldDiscountAddons: false,
        duplicateMode: false,
        isArchived: false,
        showInFreeStuff: true,
        showActiveTimePeriodCountdown: false,
        isRedemptionsMaxUnlimited: false,
        superCodeEligible: false,
        nonCombinable: false,
        inWaitlistMode: false,
        waitlistMessage: "",
        usesWaitlistApi: false,
        waitlistSize: 100,
        triggerDays: 30,
        discountOptions: {
            options: {
                option1: 20,
                option2: 25,
                option3: 30
            }
        },
        isEmployeeMode: false
    };

    if (promotion && editMode) {
        return {
            name: promotion.name || defaultValues.name,
            applyTaxToSnackpassContribution:
                promotion.applyTaxToSnackpassContribution || false,
            nameForStore: promotion.nameForStore || "",
            marketingName: promotion.marketingName || "",
            isRunning: false,
            isKioskEligible: promotion.isKioskEligible || false,
            code: promotion.code || "",
            codes: promotion.codes || [],
            showActiveTimePeriodCountdown:
                promotion.showActiveTimePeriodCountdown || false,
            isMulticode: promotion.isMulticode || false,
            discount: promotion.discount,
            discountType: promotion.discount
                ? (Object.keys(promotion.discount)[0] as DiscountSelect)
                : ("" as DiscountSelect),
            rewardExpires: promotion.rewardExpiration ? true : false,
            rewardExpirationType: getRewardExpirationType(promotion),
            rewardExpiration: promotion.rewardExpiration || null,
            rewardExpirationDays:
                promotion.rewardExpiration && promotion.rewardExpiration.seconds
                    ? secondsToDay(promotion.rewardExpiration.seconds)
                    : null,
            accounting: transformAccounting(promotion.accounting),
            conditions: {
                cartMin:
                    promotion.conditions &&
                    promotion.conditions.cartMin !== undefined
                        ? promotion.conditions.cartMin
                        : null,
                cartMax:
                    promotion.conditions &&
                    typeof promotion.conditions.cartMax !== undefined
                        ? promotion.conditions.cartMax
                        : null,
                deliveryMin:
                    promotion.conditions &&
                    typeof promotion.conditions.deliveryMin !== undefined
                        ? promotion.conditions.deliveryMin
                        : null,
                pickupOnly:
                    promotion.conditions && promotion.conditions.pickupOnly
                        ? promotion.conditions.pickupOnly
                        : false,
                deliveryOnly:
                    promotion.conditions && promotion.conditions.deliveryOnly
                        ? promotion.conditions.deliveryOnly
                        : false,
                onePerCart:
                    promotion.conditions && promotion.conditions.onePerCart
                        ? promotion.conditions.onePerCart
                        : false
            },
            storeId: promotion.store._id || "",
            storeName: promotion.store.name || "",
            store: activeStore || null,
            storewide: promotion.storewide || false,
            type: promotion.type || ("" as PromoTypes),
            productIds: promotion.productIds || [],
            categories: promotion.categories || [],
            pointsRequired: promotion.pointsRequired,
            // deal values
            dealItems: promotion.dealItems || [],
            // TO DO: transform deal items
            dealItemsFormVersion: (promotion.dealItems || []).map(
                mapDealItemToFormVersion
            ),
            description: promotion.description || "",
            hasHours: promotion.hours ? true : false,
            hoursFormVersion: promotion.hours
                ? {
                      zone: promotion.hours.zone,
                      local: transformHoursToFormHoursVersion(
                          promotion.hours.local
                      )
                  }
                : {
                      zone: SnackpassTimezoneEnum.newYork,
                      local: []
                  },
            hours: promotion.hours || null,
            targetsFormVersion: {
                firstTime: promotion.targets.includes(PromoTarget.FirstTime),
                students: promotion.targets.includes(PromoTarget.Students),
                subscribers: promotion.targets.includes(PromoTarget.Subscribers)
            },
            targets: [],
            voidCommission: promotion.voidCommission || false,
            clientShowsRemainingUses:
                promotion.clientShowsRemainingUses || false,
            hasActiveTimePeriod: promotion.activeTimePeriod ? true : false,
            activeTimePeriod: promotion.activeTimePeriod || null,
            multiuse: promotion.multiuse || false,
            hasMaximumUses: promotion.maximumUses ? true : false,
            maximumUses: promotion.maximumUses || null,
            shouldDiscountAddons: promotion.shouldDiscountAddons || false,
            duplicateMode: false,
            isArchived: promotion.isArchived || false,
            // This grabs the showInFreeStuff from promotion, but if it is undefined it
            // defaults the field to true
            // Always use _.get, do NOT do something like:
            //      promotion.showInFreeStuff || true
            // or it will FAIL!!
            showInFreeStuff: _.get(promotion, "showInFreeStuff", true),
            imageUrl: promotion.imageUrl || null,
            isRedemptionsMaxUnlimited:
                (_.get(
                    promotion.accounting,
                    "contributionPolicies.0.conditions.redemptions.max"
                ) ?? 0) >= NUM_REDEMPTIONS_FOR_UNLIMITED_PROMO,
            superCodeEligible: promotion.superCodeEligible || false,
            nonCombinable: promotion.nonCombinable || false,
            globalCredit: promotion.globalCredit || 0,
            // Party promotion fields
            ...(promotion.type === "PARTY" && {
                tiers: (promotion as IPartyPromotion).tiers || []
            }),
            inWaitlistMode: promotion.inWaitlistMode || false,
            waitlistMessage: promotion.waitlistMessage || "",
            usesWaitlistApi: promotion.usesWaitlistApi || false,
            waitlistSize: promotion.waitlistSize || 100,
            triggerDays: promotion.triggerDays || 30,
            discountOptions:
                promotion.discountOptions || defaultValues.discountOptions,
            isEmployeeMode: promotion.isEmployeeMode || false
        };
    }
    return defaultValues;
}

// constants
export const NUM_REDEMPTIONS_FOR_UNLIMITED_PROMO = 10000000;

// options
export const discountTypeOptions = [
    { value: "percentOff", label: "Percent Off" },
    { value: "dollarsOff", label: "Dollars Off" },
    { value: "newPrice", label: "New Price" },
    { value: "isDoublePoints", label: "Double Points" }
];

export const promoTypeOptions: { label: string; value: PromoTypes }[] = [
    { label: "Reward", value: PromoTypes.Reward },
    { label: "Discount", value: PromoTypes.Discount },
    { label: "Deal", value: PromoTypes.Deal },
    { label: "Time Bomb", value: PromoTypes.TimeBomb },
    { label: "Referral", value: PromoTypes.Referral },
    { label: "Promo Code", value: PromoTypes.PromoCode },
    { label: "Party", value: PromoTypes.Party },
    { label: "Birthday", value: PromoTypes.Birthday },
    { label: "Retargeting", value: PromoTypes.Retargeting }
];

// We use days as the label for seconds, but the form will convert the seconds
// value (if it exists) into days for a separate form input. Upon saving, the
// form will compute days back into seconds.
export const rewardExpirationOptions = [
    { label: "Days", value: "seconds" },
    { label: "Date", value: "date" }
];

export const subsidizationOptions = [
    { label: "Dollars", value: "dollars" },
    { label: "Percent", value: "percent" }
];

export const subsidizationConditionOptions = [
    { label: "Redemptions", value: "redemptions" },
    { label: "Dollars Discounted", value: "dollarsDiscounted" }
];

Yup.addMethod(Yup.number, "isCurrency", function () {
    return this.transform((currentValue, originalValue) => {
        if (validator.isCurrency(originalValue.toString())) {
            return Yup.number();
        }
        return Yup.ValidationError;
    });
});

// Yup validation schema
export const discountValidation = Yup.object().shape({
    percentOff: Yup.number()
        .test(
            "maxDigitsAfterDecimal",
            "Percent off discount can only have up to 2 digits after decimal",
            (number) =>
                typeof number === "undefined" ||
                /^\d*(\.\d{0,2})?$/.test(number)
        )
        .positive()
        .min(0, "Percent off discount needs to be greater than 0")
        .max(100, "Percent off discount cannot be greater than 100"),
    dollarsOff: Yup.number()
        .test(
            "maxDigitsAfterDecimal",
            "Dollars off discount can only have up to 2 digits after decimal",
            (number) =>
                typeof number === "undefined" ||
                /^\d*(\.\d{0,2})?$/.test(number)
        )
        .min(0),
    newPrice: Yup.number()
        .test(
            "maxDigitsAfterDecimal",
            "New price discount can only have up to 2 digits after decimal",
            (number) =>
                typeof number === "undefined" ||
                /^\d*(\.\d{0,2})?$/.test(number)
        )
        .min(0),
    isDoublePoints: Yup.boolean()
});

const optionsValidation = Yup.object().shape({
    option1: Yup.number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        .positive("Cannot be negative")
        .min(15, "Percent off discount needs to be greater than 15")
        .max(100, "Percent off discount cannot be greater than 100")
        .required(),
    option2: Yup.number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        .positive("Cannot be negative")
        .min(15, "Percent off discount needs to be greater than 15")
        .max(100, "Percent off discount cannot be greater than 100")
        .required(),
    option3: Yup.number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        .positive("Cannot be negative")
        .min(15, "Percent off discount needs to be greater than 15")
        .max(100, "Percent off discount cannot be greater than 100")
        .required()
});

/**
 * Warning: if there is invalid Yup schema, form will fail silently
 * and go straight to submit. Need to fix this.
 */

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is required")
        .min(2, "Name must have at least 2 characters")
        .when("type", (type: PromoTypes, schema: any) => {
            if (type === "REFERRAL") {
                return schema.test({
                    name: "Referral Name Validation",
                    message:
                        'Promotions of type REFERRAL should not have "referral", "when you refer a friend", or "refer a friend" in their name',
                    test: (value: string) => {
                        if (!value) {
                            return false;
                        }
                        const nameInLowerCase = value.toLowerCase();
                        return (
                            !nameInLowerCase.match(/referral.*/) &&
                            !nameInLowerCase.includes(
                                "when you refer a friend"
                            ) &&
                            !nameInLowerCase.includes("refer a friend")
                        );
                    }
                });
            }
        })
        .when("type", (type: PromoTypes, schema: any) => {
            if (type === "PARTY") {
                return schema.test({
                    name: "Party Name Validation",
                    message:
                        'Party promotions should not have "% off" in their name to avoid confusion on the receipt due to multiple tiers of discounts',
                    test: (value: string) => {
                        return value ? !/% off/i.test(value) : false;
                    }
                });
            }
        })
        .when(
            ["hasHours", "targetsFormVersion"],
            (hasHours: boolean, targetsFormVersion: any, schema: any) => {
                let students = _.get(targetsFormVersion, "students", false);

                if (hasHours) {
                    return students
                        ? schema.test({
                              name: "Happy Hour Student Promotion",
                              message:
                                  "Promotion ${path} cannot contain 'happy hour' (redundant) if it is a student discount.",
                              test: (value: string) => {
                                  if (!value) {
                                      return false;
                                  }
                                  return (
                                      value.toLowerCase().includes("student") &&
                                      !value
                                          .toLowerCase()
                                          .includes("happy hour")
                                  );
                              }
                          })
                        : schema.test({
                              name: "Happy Hour name",
                              message:
                                  "Promotion ${path} must not contain word 'happy hour' if the promo is a happy hour (redundant).",
                              test: (value: string) => {
                                  if (!value) {
                                      return false;
                                  }
                                  return !value
                                      .toLowerCase()
                                      .includes("happy hour");
                              }
                          });
                }
                if (!targetsFormVersion.firstTime) {
                    return schema.test({
                        name: "First time promotion",
                        message:
                            "Promotion ${path} must have first time (under conditions) toggled on if name contains first time or first order",
                        test: (value: string) =>
                            !value.toLowerCase().includes("first time") &&
                            !value.toLowerCase().includes("first order")
                    });
                }
                return schema;
            }
        )
        .when("storeName", (storeName: string, schema: any) => {
            return schema.test({
                name: "Promotion Name Validation",
                message: `Promotions should not have "at ${storeName}" in their name`,
                test: (value: string) => {
                    if (!value) {
                        return false;
                    }
                    const nameInLowerCase = value.toLowerCase();
                    const keyWords = `at ${storeName.toLowerCase()}`;
                    return !nameInLowerCase.includes(keyWords);
                }
            });
        })
        .test(
            "no-bogos-allowed",
            'Promo name cannot contain "BOGO"',
            (name) => !name.toUpperCase().includes("BOGO")
        )
        .test(
            "Promotion Name Validation",
            'Promotions should not have "student" in their name',
            (name) => !name.toLowerCase().includes("student")
        )
        .test(
            "Promotion Name Validation",
            'Promotions should not have "pickup" in their name',
            (name) => !name.toLowerCase().includes("pickup")
        ),
    targetsFormVersion: Yup.object().shape({
        students: Yup.boolean(),
        firstTime: Yup.boolean(),
        subscribers: Yup.boolean()
    }),
    marketingName: Yup.string(),
    nameForStore: Yup.string().required("Name for store is required"),
    type: Yup.mixed()
        .oneOf(promoTypeOptions.map((PromoTypes) => PromoTypes.value))
        .required("A valid promotion type is required."),
    isEmployeeMode: Yup.boolean(),
    isKioskEligible: Yup.boolean()
        .required()
        .when(
            ["conditions", "targetsFormVersion"],
            (conditions: any, targetsFormVersion: any, schema: any) => {
                if (conditions.deliveryOnly) {
                    return schema.test({
                        name: "Delivery / Pickup Only",
                        message:
                            "Kiosk enabled promos can not have delivery or pickup only",
                        test: (value: boolean) => !value
                    });
                } else if (targetsFormVersion.students) {
                    return schema.test({
                        name: "Students Only",
                        message:
                            "Kiosk enabled promos can not be students only",
                        test: (value: boolean) => !value
                    });
                } else if (targetsFormVersion.firstTime) {
                    return schema.test({
                        name: "First Time Only",
                        message:
                            "Kiosk enabled promos can not be first time only",
                        test: (value: boolean) => !value
                    });
                } else if (targetsFormVersion.subscribers) {
                    return schema.test({
                        name: "Subscribers Only",
                        message:
                            "Kiosk enabled promos cannot be subscribers only",
                        test: (value: boolean) => !value
                    });
                }
                return schema;
            }
        ),

    code: Yup.string()
        .when(
            ["type", "isMulticode"],
            (type: PromoTypes, isMulticode: boolean, schema: any) => {
                if (type === "PROMO_CODE") {
                    if (!isMulticode) {
                        return Yup.string()
                            .required("code required for promo code promos")
                            .matches(
                                /^secret-/,
                                "Code must start with secret-"
                            );
                    }
                    return Yup.string().matches(
                        /^secret-/,
                        "Code must start with secret-"
                    );
                }
                return Yup.string();
            }
        )
        .when(["type"], (type: PromoTypes, schema: any) => {
            if (type === "PROMO_CODE") {
                return schema.test({
                    name: "No Spaces Allowed in Promo Code",
                    message: "Promo Code should not have spaces",
                    test: (promoCode: any) => !promoCode.match(/\s/)
                });
            }
            return schema;
        }),
    globalCredit: Yup.string().when(
        "storeId",
        (storeId: string, schema: any) => {
            if (storeId === PROMO_CODE_STORE_ID) {
                return Yup.number().positive();
            }
            const customErrMsg =
                "Must assign global credit from the Promo Code Store";
            return Yup.mixed().nullable(true).oneOf([null, 0], customErrMsg);
        }
    ),
    codes: Yup.mixed().when(
        "isMulticode",
        (isMulticode: boolean, schema: any) => {
            if (isMulticode) {
                // not empty array
                return Yup.array()
                    .of(Yup.string().required("Must be non empty code"))
                    .required("If multicode, at least one code must be set");
            }

            return Yup.array().of(Yup.string());
        }
    ),
    discount: discountValidation,
    discountType: Yup.string().when(
        ["type", "storeId"],
        (type: PromoTypes, storeId: string, schema: any) => {
            if (
                ["DEAL", "PARTY", "RETARGETING"].includes(type) ||
                storeId === PROMO_CODE_STORE_ID
            ) {
                return schema.nullable();
            }
            return schema.required("Discount type must be set");
        }
    ),
    storeId: Yup.string().required("Store is required."),
    storewide: Yup.boolean().when("type", (type: PromoTypes, schema: any) => {
        if (type === "PARTY") {
            return schema
                .oneOf([true], "Party Promotion needs to apply storewide")
                .required("Party promotion needs to apply storewide");
        }
        if (type === "RETARGETING") {
            return schema
                .oneOf([true], "Retargeting Promotion needs to apply storewide")
                .required("Retargeting Promotion needs to apply storewide");
        }
        return schema.required();
    }),
    categories: Yup.array().of(Yup.string()),
    productIds: Yup.array()
        .of(Yup.string())
        .when(
            ["storewide", "categories", "type"],
            (
                storewide: boolean,
                categories: string[],
                type: PromoTypes,
                schema: any
            ) => {
                if (
                    !storewide &&
                    categories &&
                    categories.length === 0 &&
                    type !== "DEAL"
                ) {
                    return schema.required(
                        "Target products are required if no categories are selected and promo is not storewide."
                    );
                }
                // if storewide, don't require
                return schema;
            }
        ),
    pointsRequired: Yup.string().when(
        "type",
        (type: PromoTypes, schema: any) => {
            if (type === "REWARD") {
                return Yup.number()
                    .min(1)
                    .required("Must select number of points");
            }
            return Yup.number()
                .max(0)
                .required("Non rewards cannot have points required");
        }
    ),
    dealItemsFormVersion: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required("Deal Item Name required"),
                categories: Yup.array().of(Yup.string()),
                productIds: Yup.array()
                    .of(Yup.string())
                    .when(
                        ["categories"],
                        (categories: string[], schema: any) => {
                            if (categories && categories.length === 0) {
                                return schema.required(
                                    "Target products are required if no categories are selected."
                                );
                            }
                            return schema;
                        }
                    ),
                // todo add better discount validation
                discount: discountValidation.nullable(true),
                isDiscounted: Yup.boolean(),
                discountType: Yup.mixed().when(
                    "isDiscounted",
                    (isDiscount: boolean, schema: any) => {
                        if (isDiscount) {
                            return Yup.mixed()
                                .oneOf(
                                    discountTypeOptions.map((o) => o.value),
                                    "If deal item is discounted, discount type must be selected"
                                )
                                .required(
                                    "If deal item is discounted, discount type must be selected"
                                );
                        }
                        return schema;
                    }
                )
            })
        )
        .when("type", (type: string, schema: any) => {
            if (type === "DEAL") {
                return schema.test({
                    name: "Deal Items",
                    message: "Must have 2 or more items for a deal.",
                    test: (value: string) => value.length >= 2
                });
            }
            return schema;
        }),
    description: Yup.string().when("type", {
        is: (type) => type === "DEAL",
        then: Yup.string().required("Deal description is required"),
        otherwise: Yup.string()
    }),
    hoursFormVersion: Yup.object()
        .nullable(true)
        .test({
            name: "End time must be after start time",
            message: "End time must be after start time!",
            test: (value: any) => {
                return validateHoursStartEnd(value);
            }
        })
        .when("hasHours", (hasHours: boolean, schema: any) => {
            if (hasHours) {
                return schema.shape({
                    zone: Yup.string().required("zone required"),
                    local: Yup.array()
                        .of(
                            Yup.object().shape({
                                start: Yup.number().integer(),
                                end: Yup.number().integer()
                            })
                        )
                        .required("If special hours toggled, cannot be empty")
                });
            }
            return schema;
        }),
    clientShowsRemainingUses: Yup.boolean().when("type", {
        is: (type) => type === "DISCOUNT",
        then: Yup.boolean().required()
    }),
    hasActiveTimePeriod: Yup.mixed().when(
        "type",
        (type: PromoTypes, schema: any) => {
            /// must have active time period if time bomb
            if (type === "TIME_BOMB") {
                return Yup.mixed()
                    .oneOf(
                        [true],
                        "Time bomb promos must have start datetime toggled."
                    )
                    .required(
                        "Time bomb promos must have start datetime toggled."
                    );
            }
            return schema.oneOf([true, false]);
        }
    ),
    activeTimePeriod: Yup.object().when(
        ["hasActiveTimePeriod", "type"],
        (hasActiveTimePeriod: boolean, type: PromoTypes, schema: any) => {
            if (hasActiveTimePeriod) {
                return schema.shape({
                    startTime: Yup.date().required(
                        type === "TIME_BOMB"
                            ? "Set start time for time bomb"
                            : "Start time required"
                    ),
                    endTime: Yup.date().nullable(true)
                });
            }
            return schema.nullable(true);
        }
    ),
    showActiveTimePeriodCountdown: Yup.boolean(),
    multiuse: Yup.mixed().when(
        ["targetsFormVersion", "type"],
        (targetsFormVersion: any, type: PromoTypes, schema: any) => {
            if (type === "PARTY") {
                return schema
                    .oneOf(
                        [true],
                        "Multiuse needs to be enabled for Party Promotions. Change to true under Use Conditions"
                    )
                    .required(
                        "Multiuse needs to be enabled for Party Promotions"
                    );
            } else if (targetsFormVersion && targetsFormVersion.firstTime) {
                return schema
                    .oneOf([false], "First time discounts cannot be multiuse")
                    .required("First time discounts cannot be multiuse");
            }
            return schema
                .oneOf([true, false])
                .required("Multiuse must be specified");
        }
    ),
    nonCombinable: Yup.boolean().required(),
    hasMaximumUses: Yup.boolean().when(
        "type",
        (type: PromoTypes, schema: any) => {
            if (type === "TIME_BOMB") {
                return Yup.bool().oneOf(
                    [true],
                    "Time Bomb must have maximum uses."
                );
            }
        }
    ),
    maximumUses: Yup.number()
        .min(1)
        .when("hasMaximumUses", (hasMaximumUses: boolean, schema: any) => {
            if (hasMaximumUses) {
                return schema.required("Specify maximum uses");
            }
            return schema.nullable(true);
        }),
    rewardExpires: Yup.boolean().when("type", {
        is: (type) => type === "REFERRAL",
        then: Yup.boolean().oneOf([true], "Referrals must have expiration date")
    }),
    rewardExpiration: Yup.object()
        .shape({
            seconds: Yup.number().integer().min(1).nullable(true),
            date: Yup.date().nullable(true)
        })
        .nullable(true),
    rewardExpirationDays: Yup.mixed().when(
        ["rewardExpirationType", "rewardExpires"],
        (
            rewardExpirationType: RewardExpirationSelect,
            rewardExpires: boolean,
            schema: any
        ) => {
            if (rewardExpires && rewardExpirationType === "seconds") {
                return Yup.number()
                    .min(1)
                    .required("Must select reward expiration days");
            }
            return Yup.number().nullable(true);
        }
    ),
    // Show promotions by default
    shouldDiscountAddons: Yup.boolean().when("type", {
        is: (type) => type === "DISCOUNT",
        then: Yup.boolean().required("Set should discount addons setting"),
        otherwise: Yup.boolean()
    }),
    accounting: Yup.object()
        .shape({
            isSubsidized: Yup.boolean().required(),
            totalAmountSpent: Yup.object().shape({
                snackpass: Yup.number().required().nullable(true),
                store: Yup.number().required().nullable(true)
            }),
            commissionVoidItem: Yup.boolean(),
            // backend verified for more than one contribution policy
            contributionPolicies: Yup.array().of(
                Yup.object().shape({
                    // One of these two must have a truthy value, and the other must be null.
                    snackpassSubsidizationPercent: Yup.number()
                        .min(0)
                        .max(100)
                        .nullable(true),
                    snackpassSubsidizationDollars: Yup.number()
                        .min(0)
                        .nullable(true),
                    commissionVoid: Yup.boolean().required(),
                    conditions: Yup.object().shape({
                        redemptions: Yup.object()
                            .shape({
                                min: Yup.number()
                                    .integer()
                                    .positive()
                                    .min(0)
                                    .required(),
                                max: Yup.number()
                                    .integer()
                                    .positive()
                                    .min(0)
                                    .required()
                            })
                            .nullable(true),
                        dollarsDiscounted: Yup.object()
                            .shape({
                                min: Yup.number().positive().min(0).required(),
                                max: Yup.number().positive().min(0).required()
                            })
                            .nullable(true)
                        // not implemented yet
                        // dates: Yup.object()
                        //     .shape({
                        //         startDate: Yup.date().nullable(true),
                        //         endDate: Yup.date().nullable(true)
                        //     })
                        //     .nullable(true)
                    })
                })
            )
        })
        .test(
            "validate-subsidy-options",
            "Subsidy options must be filled for subsidized promos.",
            (accounting) => {
                if (!accounting.isSubsidized) {
                    return true;
                }

                // We currently set at most one contributionPolicy.
                const policy = accounting.contributionPolicies[0];
                const subsidizationVal =
                    policy.snackpassSubsidizationPercent ||
                    policy.snackpassSubsidizationDollars;
                if (!isTruthy(subsidizationVal)) {
                    return false;
                }

                const conditions = policy.conditions;
                return isTruthy(
                    conditions.redemptions || conditions.dollarsDiscounted
                );
            }
        ),
    conditions: Yup.object()
        .when("type", (type: PromoTypes, schema: any) =>
            schema.shape({
                cartMin: Yup.number()
                    .transform((value: any) => {
                        return value !== 0 ? value || null : 0;
                    })
                    .test({
                        name: "Minimum party purchase",
                        test: (min: number | null) => {
                            if (type === "PARTY") {
                                return min !== null ? min >= 1 : true;
                            }
                            return true;
                        },
                        message: "Minimum cart value must be $1 or higher"
                    })
                    .nullable(),
                cartMax: Yup.number().positive().nullable(),
                pickupOnly: Yup.boolean(),
                deliveryOnly: Yup.boolean(),
                onePerCart: Yup.boolean()
            })
        )
        .test({
            name: "Fulfillment Restriction",
            test: (conditions) =>
                !(conditions.pickupOnly && conditions.deliveryOnly),
            message:
                '"Pickup only" and "Delivery only" cannot both be selected.'
        }),
    imageUrl: Yup.string().when("type", (type: PromoTypes, schema: any) =>
        schema
            .test({
                name: "Promo Url",
                message: "Promotion imageUrl must be a valid url.",
                test: (value: string) => !value || urlRegex().test(value)
            })
            .nullable(true)
    ),
    showInFreeStuff: Yup.boolean()
        .when("hasHours", {
            is: true,
            then: Yup.boolean().oneOf(
                [true],
                "Happy hours must show up in discounts section."
            )
        })
        .when("inWaitlistMode", {
            is: true,
            then: Yup.boolean().oneOf(
                [true],
                "Promos in waitlist mode must be visible in discounts section."
            )
        }),
    inWaitlistMode: Yup.boolean().when("isKioskEligible", {
        is: true,
        then: Yup.boolean().oneOf(
            [false],
            "Promos in waitlist mode are not eligible for kiosk."
        )
    }),
    waitlistMessage: Yup.string(),
    usesWaitlistApi: Yup.boolean(),
    waitlistSize: Yup.number(),
    tiers: Yup.array().of(
        Yup.object().shape({
            conditions: Yup.object().shape({
                conditionType: Yup.string().oneOf(["NUM_PEOPLE"]).required(),
                minPeople: Yup.number()
                    .min(2, "Minimum amount of people in a party is 2")
                    .required(),
                maxPeople: Yup.number()
                    .transform((value: any) => value || null)
                    .when("minPeople", (minPeople: number, schema: any) => {
                        return schema.test({
                            test: (maxPeople: number | null) => {
                                return maxPeople ? minPeople < maxPeople : true;
                            },
                            message: "Max should be > min"
                        });
                    })
                    .nullable()
            }),
            discount: discountValidation
        })
    ),
    discountOptions: Yup.object().when(
        "type",
        (type: PromoTypes, schema: any) => {
            if (type === "RETARGETING") {
                return schema
                    .shape({
                        options: optionsValidation
                    })
                    .required(
                        "Retargeting promotions must have 3 discount options"
                    );
            }
            return schema.nullable();
        }
    ),
    triggerDays: Yup.number().positive()
});
