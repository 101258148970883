import { FieldArray, Form, FormikProps } from "formik";
import _ from "lodash";
import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { BeatLoader } from "react-spinners";
import stringifyObject from "stringify-object";

import { IOption } from "../../redux/types";
import { Button, Text } from "../../SharedComponents";
import { Colors } from "../../utils";
import { Header, Input, styles, validYupSchema } from "./components";
import PartyPromotionTiers from "./PartyPromotionTiers";

import {
    discountTypeOptions,
    promoTypeOptions,
    rewardExpirationOptions,
    dealItemTemplate,
    subsidizationOptions,
    subsidizationConditionOptions,
    validationSchema,
    NUM_REDEMPTIONS_FOR_UNLIMITED_PROMO
} from "./config";
import { FormValues, DealItemFormVersion } from "./types";
import { IPromotion, IStore, PromoTypes } from "@snackpass/snackpass-types";

export interface MyFormProps {
    storeOptions: IOption[];
    productOptions: IOption[];
    categoryOptions: IOption[];
    activeStore: IStore | null;
    editMode?: boolean;
    promotion?: IPromotion;
    onSuccess?: (updatedPromotion: IPromotion) => void;
}

export interface AdditionalProps {
    storeOptions: IOption[];
    productOptions: IOption[];
    categoryOptions: IOption[];
    editMode?: boolean;
}

const hasDiscountedDealItem = (dealItem: DealItemFormVersion) => {
    return _.get(dealItem, "isDiscounted");
};

enum NO_MAXIMUM_USES_PROMO_TYPE {
    Reward = "REWARD",
    Party = "PARTY",
    Retargeting = "RETARGETING"
}

export const FormBody = (
    props: AdditionalProps & FormikProps<FormValues> & MyFormProps
) => {
    const {
        values,
        errors,
        productOptions,
        categoryOptions,
        editMode,
        isSubmitting,
        setFieldValue,
        activeStore
    } = props;
    const hasValidationErrors = Object.keys(errors || {}).length > 0;

    const displayStoreHours = () => {
        let hoursDescription = _.get(activeStore, "hoursDescription");
        if (hoursDescription) {
            let splithours = hoursDescription.split(",");
            return (
                <div style={{ marginTop: 15, marginBottom: 15 }}>
                    <p> {_.get(activeStore, "name")} Store Hours: </p>
                    {splithours.map((range: string, index: number) => (
                        <p key={index} style={{ fontSize: 12 }}>
                            {range}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const renderRetargetingPromotion = (type: PromoTypes) => {
        if (type === "RETARGETING") {
            return (
                <div>
                    <Header label="Retargeting Promo Stuff" />
                    <Input
                        name="triggerDays"
                        label="Trigger Days"
                        type="number"
                        description={`Grant gifts to users who haven't visited ${_.get(
                            activeStore,
                            "name",
                            "this store"
                        )} for more than these days`}
                    />
                    <Input
                        name="discountOptions.options.option1"
                        label="Percent Off Option 1"
                        type="number"
                        description="First option for random discount"
                    />
                    <Input
                        name="discountOptions.options.option2"
                        label="Percent Off Option 2"
                        type="number"
                        description="Second option for random discount"
                    />
                    <Input
                        name="discountOptions.options.option3"
                        label="Percent Off Option 3"
                        type="number"
                        description="Third option for random discount"
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <Form
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
        >
            {validYupSchema(values, validationSchema)}
            <br />
            <br />
            <Header label="General" />
            <Input
                name="name"
                label="Promotion Name"
                description="User facing promotion name"
                type="text"
                required
                fastfield
            />
            <Input
                name="marketingName"
                label="Promotion Marketing Name"
                description="Overrides promotion name for features on the client app. ex: '20% Off Memorial Day 😍😍'"
                type="text"
                fastfield
            />
            <Input
                name="nameForStore"
                label=" Promotion Name For Store"
                description="Restaurant facing promotion name. Shows up on tablet unless you override below"
                type="text"
                fastfield
            />
            <Input
                name="type"
                label="Type of promo"
                required
                type="select"
                options={promoTypeOptions}
                onSelect={(value: any) => {
                    if (value === "TIME_BOMB") {
                        setFieldValue("hasMaximumUses", true);
                    } else if (
                        value === NO_MAXIMUM_USES_PROMO_TYPE.Reward ||
                        value === NO_MAXIMUM_USES_PROMO_TYPE.Party ||
                        value === NO_MAXIMUM_USES_PROMO_TYPE.Retargeting
                    ) {
                        // in case it was somehow set to true earlier when selecting TIME_BOMB
                        setFieldValue("hasMaximumUses", false);
                        if (value === NO_MAXIMUM_USES_PROMO_TYPE.Retargeting) {
                            setFieldValue("storewide", true);
                        }
                    }

                    if (value !== "DEAL") {
                        setFieldValue("dealItems", []);
                        setFieldValue("dealItemsFormVersion", []);
                    }
                }}
            />
            <Input
                name="isKioskEligible"
                label=" Is Kiosk Eligible"
                description="If true, this promo will appear on kiosks."
                type="toggle"
            />
            <Input
                name="isEmployeeMode"
                label=" Employee Mode Only"
                description=""
                type="toggle"
            />
            <Input
                name="storeName"
                label="Store"
                required
                type="read-only-text"
            />
            <Input name="imageUrl" label="Promo Image" type="image-upload" />
            {!["DEAL", "PARTY", "RETARGETING"].includes(values.type) ? (
                <div>
                    <Input
                        name="discountType"
                        label="Type of discount"
                        required
                        type="select"
                        options={discountTypeOptions}
                    />
                    {values.discountType === "percentOff" ? (
                        <Input
                            name="discount.percentOff"
                            label="Percent Off"
                            type="number"
                            description="percent off original price"
                        />
                    ) : null}
                    {values.discountType === "dollarsOff" ? (
                        <Input
                            name="discount.dollarsOff"
                            label="Dollars Off"
                            type="number"
                            description="$2 dollars off original price"
                        />
                    ) : null}
                    {values.discountType === "newPrice" ? (
                        <Input
                            name="discount.newPrice"
                            label="New Price"
                            type="number"
                            description="Product will be set to new price after discount"
                        />
                    ) : null}
                </div>
            ) : null}
            {renderRetargetingPromotion(values.type)}
            {values.type === "PROMO_CODE" ? (
                <div>
                    <Header label="Promo Code Stuff" />
                    {!values.isMulticode ? (
                        <Input
                            name="code"
                            label="Code"
                            description="Code for Promo Code. Must start with 'secret-' eg 'secret-theta2020'. "
                            type="text"
                        />
                    ) : null}
                    <Input
                        name="isMulticode"
                        label="Use multiple promo codes for a promo"
                        description=""
                        type="toggle"
                    />
                    <Input
                        name="globalCredit"
                        label="Global credit"
                        description={`Amount ($) of global credit given when this code is redeemed. ex: "5"`}
                        type="number"
                    />
                    {values.isMulticode ? (
                        <FieldArray
                            name="codes"
                            render={(arrayHelpers) => (
                                <div>
                                    {values.codes.map((code, i) => (
                                        <div key={i}>
                                            <Input
                                                name={`codes.${i}`}
                                                label={`Code ${i + 1}`}
                                                description="Code for Promo Code. Must start with 'secret-' eg 'secret-theta2020'. "
                                                type="text"
                                            />
                                            <button
                                                style={styles.button}
                                                type="button"
                                                onClick={() =>
                                                    arrayHelpers.remove(i)
                                                }
                                            >
                                                -
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        style={styles.button}
                                        type="button"
                                        onClick={() => arrayHelpers.push("")}
                                    >
                                        Add a code
                                    </button>
                                    <br />
                                    <br />
                                </div>
                            )}
                        />
                    ) : null}
                </div>
            ) : null}
            {values.type === "PARTY" ? (
                <div>
                    <Header label="Party" />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "15px 0"
                        }}
                    >
                        <Text small color={Colors.gray} center>
                            eg. 2 people {"->"} min: 2, max: 3
                            <br />3 people {"->"} min: 3, max: 4
                            <br />
                            4+ people {"->"} min: 4, max: [empty]
                        </Text>
                        <PartyPromotionTiers formProps={props} />
                    </div>
                </div>
            ) : null}
            {values.type === "REWARD" ? (
                <div>
                    <Header label="Reward Stuff" />
                    <Input
                        name="pointsRequired"
                        label="Points Required"
                        type="number"
                    />
                </div>
            ) : null}
            {["REWARD", "REFERRAL", "TIME_BOMB", "PROMO_CODE"].includes(
                values.type
            ) ? (
                <div>
                    <Header label="Reward Expiration Stuff" />
                    <Input
                        name="rewardExpires"
                        label="Reward Expires"
                        type="toggle"
                    />
                    {values.rewardExpires ? (
                        <Input
                            name="rewardExpirationType"
                            label="Reward Expiration Type"
                            description="Type of reward expiry"
                            type="select"
                            options={rewardExpirationOptions}
                        />
                    ) : null}
                    {values.rewardExpires &&
                    values.rewardExpirationType === "date" ? (
                        <Input
                            name="rewardExpiration.date"
                            label="Reward Expiration Date"
                            description="Date when reward should expire by"
                            type="datetime"
                        />
                    ) : null}
                    {values.rewardExpires &&
                    values.rewardExpirationType === "seconds" ? (
                        <Input
                            name="rewardExpirationDays"
                            label="Reward Expiration Days"
                            description="Number of days user has to redeem reward"
                            type="number"
                        />
                    ) : null}
                </div>
            ) : null}
            {values.type === "DEAL" ? (
                <div>
                    <Header label="Deal Stuff" />
                    <Input
                        name="description"
                        label="Deal Description"
                        description="Shows up on Store Screen discount row (in green)"
                        type="text"
                    />
                    {values.dealItemsFormVersion.some(
                        hasDiscountedDealItem
                    ) ? null : (
                        <div style={styles.errorContainer}>
                            <p>
                                🛑 Warning! 🛑 It is highly recommended that one
                                of the items be discounted! If unsure, ask
                                before proceeding.
                            </p>
                        </div>
                    )}
                    <FieldArray
                        name="dealItemsFormVersion"
                        render={(arrayHelpers) => (
                            <div>
                                <>
                                    {values.dealItemsFormVersion.map(
                                        (dealItem, index) => (
                                            <div
                                                key={index}
                                                style={styles.dealItemContainer}
                                            >
                                                <Input
                                                    type="text"
                                                    label="Deal Item Name"
                                                    description="Example: Entree, Free Drink, 50% Off Side"
                                                    name={`dealItemsFormVersion.${index}.name`}
                                                />
                                                <Input
                                                    type="select"
                                                    label="Products"
                                                    name={`dealItemsFormVersion.${index}.productIds`}
                                                    options={productOptions}
                                                    isMulti
                                                />
                                                <Input
                                                    type="select"
                                                    label="Categories"
                                                    name={`dealItemsFormVersion.${index}.categories`}
                                                    options={categoryOptions}
                                                    isMulti
                                                />
                                                <Input
                                                    type="toggle"
                                                    label="Is Discounted"
                                                    name={`dealItemsFormVersion.${index}.isDiscounted`}
                                                />
                                                {values.dealItemsFormVersion[
                                                    index
                                                ] &&
                                                values.dealItemsFormVersion[
                                                    index
                                                ].isDiscounted ? (
                                                    <Input
                                                        type="select"
                                                        label="Type of discount"
                                                        name={`dealItemsFormVersion.${index}.discountType`}
                                                        options={
                                                            discountTypeOptions
                                                        }
                                                    />
                                                ) : null}
                                                {values.dealItemsFormVersion[
                                                    index
                                                ] &&
                                                values.dealItemsFormVersion[
                                                    index
                                                ].isDiscounted &&
                                                values.dealItemsFormVersion[
                                                    index
                                                ].discountType ===
                                                    "percentOff" ? (
                                                    <Input
                                                        name={`dealItemsFormVersion.${index}.discount.percentOff`}
                                                        label="Percent Off"
                                                        type="number"
                                                        description="percent off original price"
                                                    />
                                                ) : null}
                                                {values.dealItemsFormVersion[
                                                    index
                                                ] &&
                                                values.dealItemsFormVersion[
                                                    index
                                                ].isDiscounted &&
                                                values.dealItemsFormVersion[
                                                    index
                                                ].discountType ===
                                                    "dollarsOff" ? (
                                                    <Input
                                                        name={`dealItemsFormVersion.${index}.discount.dollarsOff`}
                                                        label="Dollars Off"
                                                        type="number"
                                                        description="$2 dollars off original price"
                                                    />
                                                ) : null}
                                                {values.dealItemsFormVersion[
                                                    index
                                                ] &&
                                                values.dealItemsFormVersion[
                                                    index
                                                ].isDiscounted &&
                                                values.dealItemsFormVersion[
                                                    index
                                                ].discountType ===
                                                    "newPrice" ? (
                                                    <Input
                                                        name={`dealItemsFormVersion.${index}.discount.newPrice`}
                                                        label="New Price"
                                                        type="number"
                                                        description="Product will be set to new price after discount"
                                                    />
                                                ) : null}
                                                <button
                                                    style={styles.button}
                                                    type="button"
                                                    onClick={() =>
                                                        arrayHelpers.remove(
                                                            index
                                                        )
                                                    }
                                                >
                                                    -
                                                </button>
                                                <button
                                                    style={styles.button}
                                                    type="button"
                                                    onClick={() =>
                                                        arrayHelpers.push(
                                                            dealItemTemplate
                                                        )
                                                    }
                                                >
                                                    +
                                                </button>
                                            </div>
                                        )
                                    )}
                                    {values.dealItemsFormVersion.length < 1
                                        ? arrayHelpers.push(dealItemTemplate)
                                        : null}
                                    <button
                                        style={styles.button}
                                        type="button"
                                        onClick={() =>
                                            arrayHelpers.push(dealItemTemplate)
                                        }
                                    >
                                        {/* show this when user has removed all dealItemsFormVersion from the list */}
                                        Add a deal item
                                    </button>
                                </>
                            </div>
                        )}
                    />
                    <br />
                </div>
            ) : null}
            {values.type !== "DEAL" ? (
                <div>
                    <Header label="Products" />
                    <Input
                        name="storewide"
                        label="Storewide"
                        description="Apply discount to all products"
                        type="toggle"
                    />
                    {values.storewide ? null : (
                        <div>
                            <Input
                                name="productIds"
                                label="Products for Promotion"
                                description="Which products is this promo applicable for."
                                type="select"
                                options={productOptions}
                                isMulti
                            />
                            <Input
                                name="categories"
                                label="Product Categories for Promotion"
                                description="Which product categories is this promo applicable for."
                                type="select"
                                options={categoryOptions}
                                isMulti
                            />
                        </div>
                    )}
                </div>
            ) : null}
            {["RETARGETING"].includes(values.type) ? null : (
                <div>
                    <Header label="Conditions" />
                    <h4>Time Conditions</h4>
                    <p>
                        Note: All hours and datetimes are in the{" "}
                        <u>local time of the restaurant</u>.
                    </p>
                    {values.hasActiveTimePeriod || values.hasHours
                        ? displayStoreHours()
                        : null}
                    <Input
                        name="hasActiveTimePeriod"
                        label={
                            values.type === "TIME_BOMB"
                                ? "Promo Has Start Time"
                                : "Start/End Times"
                        }
                        description={
                            values.type === "TIME_BOMB"
                                ? ""
                                : "Promo has start and end date"
                        }
                        type="toggle"
                    />
                    {values.hasActiveTimePeriod ||
                    values.type === "TIME_BOMB" ? (
                        <div>
                            <Input
                                name="activeTimePeriod.startTime"
                                label={
                                    values.type === "TIME_BOMB"
                                        ? "Time bomb start time"
                                        : "Active Time Period Start Time"
                                }
                                type="datetime"
                            />
                            {values.type !== "TIME_BOMB" ? (
                                <Input
                                    name="activeTimePeriod.endTime"
                                    label="Active Time Period End Time"
                                    type="datetime"
                                />
                            ) : null}
                        </div>
                    ) : null}
                    <Input
                        name="hasHours"
                        label="Has Recurring Hours"
                        description="Promotion has recurring hours during the week. Do not use this for one time discounts, use start/end times instead."
                        type="toggle"
                    />
                    {values.hasHours ? (
                        <Input
                            name="hoursFormVersion.local"
                            label="Active Hours"
                            description="Useful for happy hours"
                            type="hours-of-week"
                            flexDirection="column"
                        />
                    ) : null}
                    {[
                        "REWARD",
                        "DISCOUNT",
                        "DEAL",
                        "TIME_BOMB",
                        "PARTY"
                    ].includes(values.type) ? (
                        <div>
                            <br />
                            <h4>Cart Conditions</h4>
                        </div>
                    ) : null}
                    {["DISCOUNT", "DEAL", "PARTY"].includes(values.type) ? (
                        <div>
                            {["PARTY"].includes(values.type) ? (
                                <Input
                                    name="conditions.cartMin"
                                    label="Cart Min ($)"
                                    type="number"
                                    placeholder="eg. 1"
                                    description="Leave empty if no min."
                                />
                            ) : (
                                <>
                                    <br />
                                    <Input
                                        name="conditions.pickupOnly"
                                        label="Pickup only"
                                        type="toggle"
                                    />
                                    <Input
                                        name="conditions.deliveryOnly"
                                        label="Delivery only"
                                        type="toggle"
                                    />
                                    <Input
                                        name="conditions.cartMin"
                                        label="Cart Min ($)"
                                        type="number"
                                        placeholder="eg. 1"
                                        description="Leave empty if no min."
                                    />
                                    <Input
                                        name="conditions.cartMax"
                                        label="Cart Max ($)"
                                        type="number"
                                        placeholder="Example: $2"
                                        description="Leave empty if no max."
                                    />
                                </>
                            )}
                        </div>
                    ) : null}
                    {[
                        "REFERRAL",
                        "REWARD",
                        "DISCOUNT",
                        "DEAL",
                        "TIME_BOMB",
                        "BIRTHDAY"
                    ].includes(values.type) ? (
                        <div>
                            <Input
                                name="conditions.onePerCart"
                                label="One per cart"
                                type="toggle"
                            />
                            {values.type === "DISCOUNT" &&
                            !values.storewide &&
                            !values.conditions?.onePerCart ? (
                                <div style={styles.errorContainer}>
                                    <p>
                                        🛑 Warning 🛑 This promo is not set to
                                        one per cart and will apply to the
                                        entire cart
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <br />
                    <h4>Use Conditions</h4>
                    {["DISCOUNT", "DEAL", "PARTY"].includes(values.type) ? (
                        <Input
                            name="multiuse"
                            label="Multi-Use"
                            description="Each user can use this promo more than once."
                            type="toggle"
                        />
                    ) : null}
                    <Input
                        name="nonCombinable"
                        label="Non-combinable promotion"
                        description="A non-combinable promotion cannot be used with other promotions in one cart."
                        type="toggle"
                        disabled={true}
                    />
                    {!["REWARD", "PARTY"].includes(values.type) ? (
                        <Input
                            name="hasMaximumUses"
                            label="Has maximum uses"
                            description="Limit the number of people who can use this promotion."
                            type="toggle"
                        />
                    ) : null}
                    {!["REWARD", "PARTY"].includes(values.type) &&
                    values.hasMaximumUses ? (
                        <Input
                            name="maximumUses"
                            label="Maximum Uses"
                            description={[
                                "The total number of times the promotion can be used by all customers.",
                                "Once the promotion is used this many times, it will automatically turn off for all customers"
                            ].join(" ")}
                            type="number"
                        />
                    ) : null}
                    {values.hasMaximumUses &&
                    values.maximumUses &&
                    values.maximumUses <= 10 ? (
                        <div style={styles.errorContainer}>
                            <p>
                                🛑 Warning 🛑 This promo can only be used{" "}
                                {values.maximumUses} times before it is turned
                                off for all customers!
                            </p>
                        </div>
                    ) : null}
                    {values.type === "DISCOUNT" && values.hasMaximumUses ? (
                        <Input
                            name="clientShowsRemainingUses"
                            label="Client shows remaining number of uses of discount"
                            type="toggle"
                            description="For discounts with maximum uses, show remaining number of uses on client. Useful for limited flash discounts Boba Ninja"
                        />
                    ) : null}
                    <div>
                        <Input
                            name="targetsFormVersion.students"
                            label="Students Only"
                            description=".edu emails"
                            type="toggle"
                        />
                        <Input
                            name="targetsFormVersion.firstTime"
                            label="First Time"
                            description="Filters customers who have never purchased at the store before."
                            type="toggle"
                        />
                        <Input
                            name="targetsFormVersion.subscribers"
                            label="Subscriber Only"
                            description="Release a promotion for your most loyal fans"
                            type="toggle"
                        />
                    </div>
                    <br />
                    <h4>Other Toggles</h4>
                    {values.type === "REFERRAL" ? (
                        <Input
                            name="superCodeEligible"
                            label="Is this promotion superCodeEligible?"
                            description="By default, referral promotions should be superCodeEligible (ex: you enter in a superCode and then receive the rewards $1 pizza and $1 boba). If a referral promotion is not superCodeEligible, it will not be offered to users when they enter in a superCode, only when they enter in a normal referral code."
                            type="toggle"
                        />
                    ) : null}
                    {values.type === "DISCOUNT" &&
                    values.discountType === "percentOff" ? (
                        <Input
                            name="shouldDiscountAddons"
                            label="Add-ons are discounted"
                            description="Addons are by default not discounted for all rewards and discounts with type new price or dollars off. Only for percent off discounts can you override this and make add-ons discounted."
                            type="toggle"
                        />
                    ) : null}
                    {values.type === "DISCOUNT" || values.inWaitlistMode ? (
                        <Input
                            name="showInFreeStuff"
                            label="Show in discounts section"
                            type="toggle"
                            description="Will appear in discounts section (green color) on the client app above the menu"
                        />
                    ) : null}
                    <Input
                        name="inWaitlistMode"
                        label="In waitlist mode"
                        type="toggle"
                        description="When true, make sure the promo is also toggled live. The promo will be visible to users but they cannot use it. They can only sign up for the waitlist."
                    />
                    {values.inWaitlistMode ? (
                        <>
                            <Input
                                name="waitlistMessage"
                                label="Waitlist message"
                                description="Overrides the default waitlist message. Appears in an alert when the waitlisted promo is tapped. User will be given option in this alert to join or not join the waitlist."
                                type="text"
                                fastfield
                            />
                            <Input
                                name="usesWaitlistApi"
                                label="Uses Waitlist API"
                                description="Setting to true will turn this into an actual referral-based waitlist system. It will also disregard any Waitlist message inputted above."
                                type="toggle"
                            />
                            {values.usesWaitlistApi && (
                                <Input
                                    name="waitlistSize"
                                    label="Waitlist Size"
                                    description="The top x number of people on the waitlist who will receive the promotion."
                                    type="number"
                                />
                            )}
                        </>
                    ) : null}
                    {["DISCOUNT", "DEAL"].includes(values.type) &&
                    values.activeTimePeriod ? (
                        <Input
                            name="showActiveTimePeriodCountdown"
                            label="Show Countdown for Discounts"
                            type="toggle"
                            description="Toggle this to show countdown on a feature for discounts (not time bombs)"
                        />
                    ) : null}

                    {values.type === "DISCOUNT" &&
                    !values.storewide &&
                    !values.showInFreeStuff ? (
                        <div style={styles.errorContainer}>
                            <p>
                                🛑 Warning 🛑 It is highly recommended that you
                                show promo in discount section for promotions
                                that are NOT storewide
                            </p>
                        </div>
                    ) : null}
                    <br />
                    <div>
                        <Header label="Accounting" />
                        {values.type !== "PARTY" ? (
                            <>
                                {!values.accounting.isSubsidized ? (
                                    <div style={styles.errorContainer}>
                                        <p>
                                            🛑 Warning 🛑 Right now, we are not
                                            paying for this promo! Should we be?
                                            If so, toggle on "Are we subsidizing
                                            this promo?"
                                        </p>
                                    </div>
                                ) : null}
                                <Input
                                    name="accounting.isSubsidized"
                                    label="Are we subsidizing this promo?"
                                    description={
                                        "There are too many edge cases with this field so we have decided to disable it. To make accounting easier, please create one-off payments to restaurants via routable for subsidized promos."
                                    }
                                    type="toggle"
                                    disabled={true}
                                />
                            </>
                        ) : null}

                        <Input
                            name="accounting.commissionVoidItem"
                            label="Does this promo void commission on any item it's applied to?"
                            description="If toggled on, Snackpass will not take commission on any item and addons that this promo applies to."
                            type="toggle"
                        />
                        {values.accounting.isSubsidized &&
                        values.accounting.contributionPolicies[0] ? (
                            <FieldArray
                                name="accounting.contributionPolicies"
                                render={(arrayHelpers) => (
                                    <div>
                                        <Input
                                            name="accounting.contributionPolicies.0.subsidizationType"
                                            label="Subsidization Type"
                                            type="select"
                                            options={subsidizationOptions}
                                        />
                                        {values.accounting
                                            .contributionPolicies[0] &&
                                        values.accounting
                                            .contributionPolicies[0]
                                            .subsidizationType === "dollars" ? (
                                            <Input
                                                name="accounting.contributionPolicies.0.snackpassSubsidizationDollars"
                                                label="Dollars Subsidized"
                                                type="number"
                                                description="If dollar subsidization is greater than discounted amount, it will cap at discounted amount. Example, if this value is $5 and we are subsidizing 50% of a $8 burrito, our subsidy will cap at $4."
                                            />
                                        ) : null}

                                        {values.accounting
                                            .contributionPolicies[0] &&
                                        values.accounting
                                            .contributionPolicies[0]
                                            .subsidizationType === "percent" ? (
                                            <Input
                                                name="accounting.contributionPolicies.0.snackpassSubsidizationPercent"
                                                label="Percent Subsidized"
                                                type="number"
                                                description="This is % of the %!!! Not % of total discount. Example: If there is a 50% off discount, and we are subsidizing 50%, we are subsidizing 50% of 50%, or 25% total. Do NOT f this up. Ask for help if you need 🛑"
                                            />
                                        ) : null}

                                        <Input
                                            name="accounting.contributionPolicies.0.subsidizationConditionsType"
                                            label="Subsidization Conditions Type"
                                            type="select"
                                            options={
                                                subsidizationConditionOptions
                                            }
                                        />
                                        {values.accounting
                                            .contributionPolicies[0] &&
                                        values.accounting
                                            .contributionPolicies[0]
                                            .subsidizationConditionsType ===
                                            "redemptions" ? (
                                            <div>
                                                <Input
                                                    name="accounting.contributionPolicies.0.conditions.redemptions.min"
                                                    label="Redemptions Min"
                                                    type="number"
                                                />
                                                <Input
                                                    name="isRedemptionsMaxUnlimited"
                                                    label="Redemptions Max is Unlimited"
                                                    description="If toggled on, Redemptions Max will be set as unlimited. If toggled off, you must enter a custom value for Redemptions Max."
                                                    type="toggle"
                                                    onToggle={(value: any) => {
                                                        const redemptionsMax =
                                                            "accounting.contributionPolicies.0.conditions.redemptions.max";
                                                        if (value) {
                                                            setFieldValue(
                                                                redemptionsMax,
                                                                NUM_REDEMPTIONS_FOR_UNLIMITED_PROMO
                                                            );
                                                        } else {
                                                            setFieldValue(
                                                                redemptionsMax,
                                                                undefined
                                                            );
                                                        }
                                                    }}
                                                />
                                                {values.isRedemptionsMaxUnlimited ? null : (
                                                    <Input
                                                        name="accounting.contributionPolicies.0.conditions.redemptions.max"
                                                        label="Redemptions Max"
                                                        type="number"
                                                    />
                                                )}
                                            </div>
                                        ) : null}
                                        {values.accounting
                                            .contributionPolicies[0] &&
                                        values.accounting
                                            .contributionPolicies[0]
                                            .subsidizationConditionsType ===
                                            "dollarsDiscounted" ? (
                                            <div>
                                                <Input
                                                    name="accounting.contributionPolicies.0.conditions.dollarsDiscounted.min"
                                                    label="Dollars Discounted Min"
                                                    type="number"
                                                />
                                                <Input
                                                    name="accounting.contributionPolicies.0.conditions.dollarsDiscounted.max"
                                                    label="Dollars Discounted Max"
                                                    type="number"
                                                />
                                            </div>
                                        ) : null}
                                        <Input
                                            name="accounting.contributionPolicies.0.commissionVoid"
                                            label="Commission void"
                                            type="toggle"
                                            description="This commission void toggle only applies to the purchases that are subsidized."
                                        />
                                        {!values.applyTaxToSnackpassContribution ? (
                                            <div style={styles.errorContainer}>
                                                <p>
                                                    🛑 Warning 🛑 Do we need to
                                                    cover the tax on this promo?
                                                    If so, toggle on "Apply tax
                                                    to snackpass contribution"
                                                </p>
                                            </div>
                                        ) : null}
                                        <Input
                                            name="applyTaxToSnackpassContribution"
                                            label="Apply tax to snackpass contribution"
                                            type="toggle"
                                            description="If toggled off, Snackpass does not pay the store tax on Snackpass subsidy. If toggled on, Snackpass will pay the store tax on Snackpass subsidy in addition to Snackpass subsidy. Turn on only when the promo is to be hidden from the restaurant."
                                        />
                                    </div>
                                )}
                            />
                        ) : null}
                    </div>
                </div>
            )}
            {editMode ? (
                <Input
                    label="Duplicate Mode"
                    name="duplicateMode"
                    type="toggle"
                    description="Toggle on to duplicate promo"
                />
            ) : null}
            <br />
            {isSubmitting ? (
                <BeatLoader color={Colors.blue} size={10} />
            ) : (
                <Button
                    disabled={hasValidationErrors}
                    label={
                        values.duplicateMode
                            ? "Duplicate"
                            : editMode
                              ? "Save"
                              : "Create"
                    }
                    style={styles.button}
                    onPress={() => props.handleSubmit()}
                    type="submit"
                />
            )}
            <hr />
            {Object.keys(errors).length ? (
                <div style={styles.errorContainer}>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                        {`${stringifyObject(errors, {
                            singleQuotes: false
                        })}`}
                    </p>
                </div>
            ) : null}
        </Form>
    );
};
