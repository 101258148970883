import { IDocumentWithTimestamps, ObjectId } from "./globals";
import { Accounting } from "./Accounting";
import { Geolocation, IStoreEmbedded } from "./Store";
import { IHoursSchema } from "./Hours";
import { Maybe } from "../utils/types";
import { Opaque, ValueOf } from "type-fest";

export interface RewardExpiration {
    _id: ObjectId;
    seconds: number | null;
    date: Date | null;
}

export interface IPromotion extends IDocumentWithTimestamps {
    accounting: Accounting;
    applyTaxToSnackpassContribution?: boolean;
    // If true, this promotion uses multiple promo codes.
    isMulticode?: boolean;

    // For legacy clients; if `isMulticode` is true, this field is ignored.
    code: string;

    // Only if `isMulticode` is true.
    codes: string[];

    dealItems: IDealItem[];
    _id: ObjectId;

    // The user-facing name of the promotion.
    name: string;

    // If given it will override the name prop in certain cases
    marketingName?: string;

    // The store-facing name of the promotion.
    nameForStore: string;

    // Filters on the users that are eligible for this promotion (combined
    // using AND).
    targets: PromoTarget[];

    // The store offering this promotion.
    store: IStoreEmbedded;

    // If present, the promotion can only be activated between these dates.
    // If absent, `hours` is used.

    // If present, the hours that this promotion is available. If not
    // present, this promotion is always available. `activeTimePeriod` takes
    // precedence over `hours`.
    hours: IHoursSchema | null;

    // Whether this promotion can be redeemed more than once.
    multiuse: boolean;
    maximumUses?: number | null;

    // Whether or not this promotion is designated for
    // employee mode on kiosks only.
    isEmployeeMode: boolean | null;

    // If true, `productIds` and `categories` is ignored and this promotion
    // applies to all products.
    storewide: boolean;

    categories: string[];
    // The products that this promotion is eligible for.
    productIds: ObjectId[];

    // The number of points necessary to redeem this promotion.
    pointsRequired: number;
    invitesRequired?: number;

    // The first of these that exists will be applied when the promotion is
    // activated.
    // only applies for Discount type Promotions
    discount?: IDiscount;

    // for promo codes which award global credit
    globalCredit?: number;
    storeCredit?: number | null;

    // The type of the promotion (see sub-models below).
    type: PromoTypes;

    voidCommission: boolean;
    totalUses?: number;
    shouldDiscountAddons?: boolean;
    activeTimePeriod: {
        startTime: Date | string | null;
        endTime: Date | string | null;
    } | null;

    conditions?: IConditions;
    description?: string;
    rewardExpiration: null | RewardExpiration;
    isRunning: boolean;
    totalPurchases?: number;
    newCustomerPurchases?: number;
    clientShowsRemainingUses?: boolean;
    tabletShowsPromotionDiscount?: boolean;
    promotionCreator?: "snackpass" | "store" | "both";
    // this is actually used as show in discounts section
    showInFreeStuff: boolean;
    isArchived: boolean;
    showActiveTimePeriodCountdown: boolean;
    partySize?: number;
    maximumPartiesPerPerson?: number | null;
    imageUrl?: string | null;
    superCodeEligible?: boolean;
    geolocation: Geolocation | null;
    isKioskEligible?: boolean;
    nonCombinable: boolean;
    lastUsedAt?: Date | string | null;
    lastPurchaseAt?: Date | string | null;
    isLeadPromotion?: boolean;
    inWaitlistMode?: boolean;
    waitlistMessage?: string;
    waitlistKey?: string;
    usesWaitlistApi?: boolean;
    waitlistSize?: number;
    triggerDays?: number;
    discountOptions?: DiscountOptions;
    //** The number of times a promotion was the first promo a new user used */
    firstPromotionUses?: number;
    isSubscriberOnly?: boolean;

    /** Automatically apply this promotion to the user's cart (on Kiosk). */
    autoApply?: boolean;

    /** Do not change Bag Fee when this promotion is applied. */
    zeroBagFee?: boolean;
}

export interface IPromotionEmbedded {
    _id: ObjectId;
    name: string;
}

export type IAnnotatedPromotion = IPromotion & {
    store: IPromotion["store"] & { region: string };
};

export interface IDealItem {
    _id: string;
    discount?: IDiscount | null;
    name: string;
    productIds: string[];
    categories?: string[];
}

export interface IRewardExpiration {
    _id: ObjectId;
    seconds: number;
    date: Date;
}

export enum PromoTypes {
    Reward = "REWARD",
    Discount = "DISCOUNT",
    Deal = "DEAL",
    TimeBomb = "TIME_BOMB",
    Referral = "REFERRAL",
    PromoCode = "PROMO_CODE",
    Birthday = "BIRTHDAY",
    Party = "PARTY",
    Retargeting = "RETARGETING",
    GiftCard = "GIFT_CARD",

    // We are using this enum as the discriminator for our promotion model. We should maintain capitalized case instead.
    SpecialPromotion = "SpecialPromotion"
}

export enum PromoTarget {
    All = "all",
    FirstTime = "firstTime",
    SecondTime = "secondTime",
    Students = "students",
    Subscribers = "subscribers",
    Campaign = "campaign"
}

/** @deprecated use PromoTarget instead */
export type Targets = (
    | "all"
    | "firstTime"
    | "secondTime"
    | "students"
    | "subscribers"
    | "campaign"
)[];

export type IDiscount = {
    newPrice?: number;
    percentOff?: number;
    dollarsOff?: number;
    isDoublePoints?: boolean;
    maximumDiscount?: number;
};

export enum PartyPromoTierCondition {
    NumPeople = "NUM_PEOPLE"
}

export interface IPartyPromoTier {
    conditions: {
        conditionType: PartyPromoTierCondition;
        minPeople: number;
        maxPeople: number | null;
    };
    discount: IDiscount;
}

export interface IPartyPromotion extends IPromotion {
    type: PromoTypes.Party;
    tiers: IPartyPromoTier[];
}

export type DiscountKey = keyof IDiscount;

export interface IConditions {
    cartMin?: number | null;
    cartMax?: number | null;
    deliveryMin?: number | null;
    pickupOnly?: boolean;
    deliveryOnly?: boolean;
    onePerCart?: boolean;
    minimumPrice?: number; // minimum price of this item for the promotion to be able to apply
}

export interface DiscountOptions {
    options: {
        option1: number;
        option2: number;
        option3: number;
    };
}

interface IPromotionDocument extends IPromotion {
    _id: string;

    isActive(): boolean;

    isUsable(): boolean;

    getValidCodes(): string[];

    tabletShowsPromotionDiscount: boolean;
    promoVisibilityWasModified: boolean;
}

export type Promotion = Omit<
    IPromotionDocument,
    | "id"
    | "_id"
    | "store"
    | "totalPurchases"
    | "totalUses"
    | "maximumUses"
    | "clientShowsRemainingUses"
    | "inWaitlistMode"
    | "lastUsedAt"
    | "createdAt"
    | "type"
> & {
    id: string;
    store: PromotionStore;
    totalPurchases: number;
    totalUses: number;
    maximumUses: Maybe<number>;
    clientShowsRemainingUses: boolean;
    inWaitlistMode: boolean;
    lastUsedAt: Maybe<Date>;
    createdAt: Date;
    type: PromoTypes;
};

/** wrapper for readability */
export type MongoPromotion = Opaque<IPromotionDocument>;

export type PromotionStore = {
    id: string;
    color: string;
    name: string;
    emoji: string;
    thumbnailUrl?: string;
    logoUrl?: string;
};
